import { api } from "@/app"
import { Flag, Publication, FlagWithComment } from "@/types"
import { PaginationResponse, ReactQuery, UsePaginationTypes } from "@codeleap/common"
import { QueryKeys } from "."

const BASE_URL = 'article/flags/'

type AssignFlagParams = {
  data: {
    flag: number,
    comment?: string
  }[],
  article: Publication['id']
}

export async function list() {
  const response = await api.get<PaginationResponse<Flag>>(BASE_URL)

  return response.data.results
}

export async function publication(article: Publication['id']) {
  const response = await api.get<FlagWithComment[]>(`${BASE_URL + article}/`)

  return response.data
}

export async function update({ data, article }: AssignFlagParams) {
  const response = await api.patch(`${BASE_URL}update-comments/${article}/`, data)

  return response.data
}

export async function assign({ data, article }: AssignFlagParams) {
  const response = await api.post(`${BASE_URL}assign/${article}/`, data)

  return response.data
}

export const useFlags = () => {
  return ReactQuery.useQuery({
    queryKey: QueryKeys.flags.key,
    queryFn: list,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!QueryKeys.me.getData(),
  })
}

export const usePublicationFlags = (article: Publication['id']) => {
  return ReactQuery.useQuery({
    queryKey: [`${article}-flag`],
    queryFn: () => publication(article),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: true,
    initialData: null,
  })
}