import { variantProvider } from '@/app'
import {
  ExpandableView,
  JournalFieldEditor,
  JournalTab,
  JournalURLDomainInput,
  Text,
  TextInput,
  useJournalCrudContext,
  View,
} from '@/components'
import { JournalsUtils } from '@/utils'
import { onUpdate, useDebounce, useImperativeHandle, useState } from '@codeleap/common'
import { journalImageResolutionText, portalImageResolutionText } from '../CrudContext/defaultSettings'
import { PicturePicker } from '../Crud/PicturePicker'

export function PortalPreferencesTab({ tab, form }) {
  const { isCreate } = useJournalCrudContext()
  const ref = useJournalCrudContext(v => v.modulesRefs.preferences)
  const [isValid, setIsValid] = useState<boolean>(false)
  const preferencesTab = JournalsUtils?.segmentedControlOptions[0]?.value

  const debouncedFields = {}

  const disableUrlValidation = !isCreate

  const defaultValidateFields = ['title', 'description', 'issn', 'aims_scope', 'editorial_board', 'portal_footer']

  const fieldsToValidate = disableUrlValidation ? [...defaultValidateFields] : [...defaultValidateFields, 'url_domain']

  fieldsToValidate.forEach((field) => {
    debouncedFields[field] = useDebounce(form?.values?.[field], 400)
  })

  const debouncedDependencies = Object?.values(debouncedFields)

  onUpdate(() => {
    setIsValid(form?.validateMultiple?.(fieldsToValidate)?.valid)
  }, [form?.isValid, ...debouncedDependencies])

  useImperativeHandle(ref, () => ({
    anchor: JournalsUtils.getInvalidKey(form?.fieldErrors) === 'issn' ? JournalsUtils.journalsAnchors.issn : JournalsUtils.journalsAnchors.general,
    fieldErrors: form.fieldErrors,
    isValid,
    tab: preferencesTab,
  }))

  return (
    <JournalTab currentTab={tab} tab='preferences' style={{ gap: 40 }}>
      <ExpandableView title='General' initialState contentVariants={['fullWidth', 'column', 'gap:3']} >
        <PicturePicker
          field='cover'
          label={'Portal cover'}
          renderDescription={() => <Text text={portalImageResolutionText} variants={['p4', 'marginTop:0.5']} />}
        />

        <View variants={['row', 'gap:6', 'fullWidth', 'alignStart', 'justifyStart']}>
          <PicturePicker
            field='image'
            label='Journal Logo'
            renderDescription={() => (
              <Text text={journalImageResolutionText} variants={['p4', 'marginTop:0.5']} style={styles.description} />
            )}
          />
          <PicturePicker
            field='publisher_logo'
            label='Publisher Logo'
            renderDescription={() => (
              <Text text={journalImageResolutionText} variants={['p4', 'marginTop:0.5']} style={styles.description} />
            )}
          />
        </View>

        <View variants={['column', 'fullWidth']} id={JournalsUtils.journalsAnchors.general}>
          <TextInput {...form.register('title')} />
          <JournalFieldEditor
            field='description'
            placeholder='Description'
            label='Description'
            renderErrorSpace
            excludeIds={['textStyles', 'highlight']}
            min={1}
          />
          <JournalURLDomainInput />
        </View>
      </ExpandableView>

      <ExpandableView title='Journal portal instructions' initialState contentVariants={['fullWidth', 'column']} >
        <JournalFieldEditor
          field='aims_scope'
          placeholder='Aims and Scope'
          label='Aims and Scope'
          renderErrorSpace
          excludeIds={['textStyles', 'highlight']}
        />
        <View variants={['fullWidth', 'fullHeight']} id={JournalsUtils.journalsAnchors.issn}>
          <TextInput {...form.register('issn')} />
        </View>
        <JournalFieldEditor
          field='editorial_board'
          placeholder='Editorial Board'
          label='Editorial Board'
          renderErrorSpace
          excludeIds={['textStyles', 'highlight']}
        />
      </ExpandableView>

      <ExpandableView title='Journal portal footer' initialState contentVariants={['fullWidth', 'column']}>
        <JournalFieldEditor
          field='portal_footer'
          placeholder='Portal footer'
          excludeIds={['textStyles', 'highlight']}
        />
      </ExpandableView>
    </JournalTab>
  )
}

const DESCRIPTION_MAX_WIDTH = 185

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    description: {
      maxWidth: DESCRIPTION_MAX_WIDTH,
    },
  }),
  true,
)
