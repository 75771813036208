import { applyTextMaxLines, formatFileName } from '@/utils'
import { View, Text, FlagsList, FlagsListProps } from '@/components'
import { variantProvider } from '@/app'
import { Flag } from '@/types'

export type NameColumnProps = {
  title?: string
  organisation?: string
  file?: string
  flags?: Flag[]
  id: number
  author?: string
  showAuthor?: boolean
  showFlags?: boolean
  flagsListProps?: Partial<FlagsListProps>
}

export const NameColumn = ({
  title,
  organisation,
  file,
  author,
  id,
  flags,
  showAuthor,
  showFlags,
  flagsListProps
}: NameColumnProps) => {
  return (
    <View variants={['column', 'gap:0.5', 'fullWidth']}>
      <Text
        text={title || formatFileName(file)}
        variants={['p1', 'color:neutral8']}
        css={[styles.nameColumnTitle]}
      />
      {(showAuthor || organisation) && (
        <Text variants={['p3', 'color:neutral7']} css={applyTextMaxLines(1)}>
          {showAuthor && `by ${author}`}
          {showAuthor && organisation && ' - '}
          {organisation}
        </Text>
      )}

      {showFlags ? <FlagsList type='list' flags={flags} manuscriptId={id} compact showAdd {...flagsListProps}/> : null}
    </View>
  )
}

const LINE_HEIGHT = 18
const MAX_LINES = 4

const styles = variantProvider.createComponentStyle((theme) => ({
  nameColumnTitle: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    lineHeight: LINE_HEIGHT + 'px',
    maxHeight: LINE_HEIGHT * MAX_LINES,
    ...theme.spacing.margin(0),

    WebkitLineClamp: MAX_LINES,
    WebkitBoxOrient: 'vertical',

    overflow: 'hidden',
    textOverflow: 'ellipsis',

    wordBreak: 'break-all',
  },
  subHeading: {
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    wordBreak: 'break-word',

  },
}), true)
