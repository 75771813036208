import { React, Theme } from '@/app'
import { Toaster, usePublicationForm, Button, Text, View, Icon, Link } from '@/components'
import { APIClient } from '@/services'
import { PublicationStatus } from '@/types'
import { IProgressBanner, progressBannerStatus } from './content/progressBannerStatus'

type ProgressBannerProps = {
  status?: keyof typeof PublicationStatus
  currentBanner?: IProgressBanner
}

const BannerLeftContent = ({ title, body = '', linkText, linkUrl, icon, iconColor, backgroundColor }) => {
  return (
    <View variants={['fullWidth', 'row', 'alignCenter', 'gap:2']}>
      <Icon
        name={icon}
        variants={['size:3', 'noShrink']}
        color={Theme.colors.light?.[iconColor]}
        debugName=''
      />
      <View variants={['fullWidth', 'column', 'gap:1']}>
        <View variants={['column', 'gap:0.5']}>
          <Text text={title} variants={['p2', 'semiBold', 'color:neutral10', 'fullWidth']}/>
          {body ? <Text variants={['p3', 'color:neutral10', 'fullWidth']}>
            {body}
          </Text> : null}
        </View>

        {linkUrl ? <View><Link openNewTab to={linkUrl} text={linkText || linkUrl} variants={['p1', 'neutral10']}/></View> : null}
      </View>
    </View>
  )
}


const BannerRightContent = ({ onPress, text="Action" }) => {
  return (
    <Button
      debugName="ProgressBanner action"
      text={text}
      /*@ts-ignore*/
      variants={['small']}
      onPress={onPress}
    />
  )
}

export const ProgressBanner = ({ status: _status, currentBanner: _currentBanner }: ProgressBannerProps) => {

  const status = !_currentBanner && (_status || usePublicationForm(v => v.publication?.status))
  const { profile } = APIClient.Session.useSession()

  const currentBanner = _currentBanner || progressBannerStatus?.[status]?.[profile?.current_role]

  if (!currentBanner) return null

  return (
    <Toaster.Component
      LeftContent={() => <BannerLeftContent icon="info" {...currentBanner}/>}
      // icon='info'
      RightContent={() => currentBanner?.onPress ? <BannerRightContent text={currentBanner?.buttonText} onPress={() => currentBanner?.onPress?.()} /> : null}
      variants={["progressBanner"]}
      isVisible
      styles={{
        wrapper: {
          backgroundColor: Theme.colors.light[currentBanner.backgroundColor],
        },
      }}
    />
  )
}