import { React, variantProvider } from '@/app'
import { View, Text } from '@/components'
import { useMemo } from '@codeleap/common'

export type ProgressBarProps = {
  leftText?: string
  rightText?: string

  leftCurrVl: number
  leftTotal: number

  rightCurrVl: number
  rightTotal: number

}

export const ProgressBar = (props: ProgressBarProps) => {
  const { leftText, rightText, leftCurrVl, leftTotal, rightCurrVl, rightTotal } = props

  const finalProgress = useMemo(() => {
    const sentProgress = leftCurrVl / leftTotal
    const acceptedProgress = rightCurrVl / rightTotal
    return ((0.6 * sentProgress) + (0.4 * acceptedProgress)) * 100
  }, [leftCurrVl, leftTotal, rightCurrVl, rightTotal])

  return (
    <View variants={['column', 'gap:1', 'marginTop:1']}>
      <View style={styles.progressWrapper}>
        <View css={[styles.progressColumn, { width: `${finalProgress}%` }]}/>
      </View>
      <View variants={['row', 'alignCenter', 'justifySpaceBetween']}>
        <Text variants={[`p3`, 'color:neutral9']} text={`${leftText} ${leftCurrVl}/${leftTotal}`}/>
        <Text variants={[`p3`, 'color:neutral9']} text={`${rightText} ${rightCurrVl}/${rightTotal}`}/>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  progressWrapper: {
    height: 34,
    width: '100%',
    backgroundColor: theme.colors.positive1,
    borderRadius: 6,
    overflow: 'hidden',
  },
  progressColumn: {
    height: '100%',
    backgroundColor: theme.colors.positive2,
  },
}), true)
