import { React } from '@/app'
import {
  View,
  Text,
  usePublicationForm,
  Checkbox,
  Modal,
  TextEditorExtensions,
  TextEditor,
  ActivityIndicator,
} from '@/components'
import { APIClient } from '@/services'
import { Disclaimer } from '@/types'
import { modal, PublicationUtils, useDisclaimers } from '@/utils'
import { PropsOf, useImperativeHandle, useState } from '@codeleap/common'
import { useEditor } from '@tiptap/react'

type DisclaimerModalProps = PropsOf<typeof Modal> & {
  disclaimer?: Disclaimer
}

const DisclaimerModal = modal<DisclaimerModalProps>({ independent: true })
  .content(({ disclaimer }) => {
    const editor = useEditor({ content: disclaimer?.body, editable: false, extensions: TextEditorExtensions })

    return (
      <View variants={['column']}>
        <TextEditor
          toolbarComponentProps={{ editor, hide: true }}
          disabled
          textEditorProps={{
            editor,
            variants: ['flat', 'announcements'],
          }}
        />
      </View>
    )
  })
  .props({
    styles: { body: { minWidth: 700 }},
  })

export const DisclaimersForm = () => {
  const publication = usePublicationForm(v => v.publication)
  const inDrafts = usePublicationForm(v => v.isSaved_in_drafts)
  const ref = usePublicationForm(v => v.modulesRefs.disclaimers)

  const { isAuthor, profile } = APIClient.Session.useSession()

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const disclaimers = useDisclaimers(
    publication?.journal?.id || profile?.current_journal,
    publication?.disclaimers_accepted,
  )

  const hasChanges = publication?.disclaimers_accepted?.length !== disclaimers?.acceptedDisclaimers?.length

  const acceptDisclaimer = (key: Disclaimer['id'], value: boolean) => {
    disclaimers.handleAcceptance(key, value)
    setShowErrorMessage(disclaimers.allAccepted)
  }

  const onSave = async () => {
    if (hasChanges) {
      await APIClient.Publishers.sendAcceptedDisclaimers(publication.id, disclaimers.acceptedDisclaimers)
    }
  }

  useImperativeHandle(ref, () => ({
    onValidate: () => ({ isValid: disclaimers.allAccepted, anchor: PublicationUtils.fieldAnchors.disclaimers }),
    onSave,
    onClear: () => {},
    showError: () => setShowErrorMessage(!disclaimers.allAccepted),
    hasChanges,
  }))

  if (disclaimers.isLoading) {
    return (
      <View variants={['fullWidth', 'center', 'column', 'marginBottom:5']}>
        <ActivityIndicator variants={['fullWidth', 'center']} debugName='disclaimers activity indicator' />
        <Text variants={['p1']} text='Loading disclaimers...' />
      </View>
    )
  }

  return (
    <View variants={['gap:2', 'column']}>
      {!isAuthor ? null : (
        <Text variants={[`h5`, 'row', 'color:neutral8']} text='By submitting this manuscript you agree with' />
      )}
      <View variants={['gap:1.25', 'column']}>
        {disclaimers.items.map(disclaimer => {
          const handleLinkPress = () => {
            if (!!disclaimer.url) {
              window.open(disclaimer.url, '_blank')
              return
            }

            DisclaimerModal.open({ disclaimer, title: disclaimer?.title })
          }

          return (
            <View key={disclaimer?.id} variants={['row', 'gap:2', 'alignStart']}>
              <Checkbox
                debugName={`author disclaimer ${disclaimer?.id}`}
                variants={['noError']}
                value={disclaimers.disclaimerForm?.[disclaimer?.id]}
                onValueChange={v => acceptDisclaimer(disclaimer?.id, v)}
                disabled={!inDrafts}
              />
              <Text
                text={disclaimer?.title}
                variants={['link', 'p1', 'regular', 'color:neutral10', 'cursor:pointer']}
                onPress={handleLinkPress}
              />
            </View>
          )
        })}
        {!showErrorMessage ? null : (
          <Text
            variants={['p4', 'color:destructive2', 'paddingLeft:2', 'marginTop:-0.25']}
            text='You must accept all disclaimers to submit the manuscript'
          />
        )}
      </View>
      <DisclaimerModal.Component />
    </View>
  )
}
