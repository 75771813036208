import { I18N } from '@/app'
import { Text, View } from '@/components'
import { QueryKeys } from '@/services/api'
import { ViewProps } from '@codeleap/web'

type TemplateVariablesProps = {
  type: 'proposal' | 'publication'
} & ViewProps<'div'>

export const TemplateVariables = ({ type, variants = [], ...props }: TemplateVariablesProps) => {
  const vars = type === 'proposal' ? QueryKeys.proposalEmailVariables.getData() : QueryKeys.journalEmailVariables.getData()
  return (
    <View variants={['column', 'gap:1', ...variants]} {...props}>
      <Text variants={['p2', 'color:neutral8']} text='Variables list' />
      <Text variants={['p3', 'color:neutral7']} text={I18N.t('templateVariables.description')} />
      <View variants={['column', 'gap:0.5']} responsiveVariants={{ smallish: ['gap:1'] }}>
        {Object.entries(vars || {}).map((variable) => {
          return (
            <View variants={['row', 'gap:1']} responsiveVariants={{ smallish: ['column', 'gap:0.5'] }}>
              <Text
                variants={[
                  'p2',
                  'color:neutral8',
                  'backgroundColor:neutral2',
                  'border-radius:nanoish',
                  'paddingHorizontal:0.25',
                  'regular',
                ]}
                style={{ width: 'fit-content' }}
                text={`{${variable[0]}}`}
              />
              <Text variants={['p2', 'color:neutral7', 'regular']} text={variable[1]} />
            </View>
          )
        })}
      </View>
    </View>
  )
}
