/* eslint-disable max-len */
import { useRef } from '@codeleap/common'
import { ScrollerProps } from './misc'
import { MutableRefObject } from 'react'

export type ErrorModule = {
  isValid: boolean
  errorMessage?: string
  anchor?: string
  scrollProps?: ScrollerProps
}

export type ModuleRef = {
  hasChanges?: boolean
  onValidate?: () => ErrorModule
  onSave: () => void
  onClear: () => void
  showError?: () => void
}

type ObjectKeyType = string | number | symbol
export type ModulesRef<T extends ObjectKeyType> = Record<T, MutableRefObject<ModuleRef>>

const getLazyRef = <T, K = ModuleRef>() => useRef<T & K>(null)

const getModuleError = <T>(modules: T): ErrorModule => {
  for (const module of Object.values(modules)) {
    const validation = module.current.onValidate()

    if (!validation.isValid) {
      return validation
    }
  }
}

const applyFocusAndBlur = (element: HTMLElement) => {
  element.focus({ preventScroll: true })
  element.blur()
}

type Anchors = { [key: string]: string }
const handleFocusBlur = (anchors: Anchors) => {
  Object.values(anchors).forEach((anchorId) => {
    const section = document.getElementById(anchorId)
    if (section) {
      const inputs = section.querySelectorAll('input')

      inputs.forEach(input => {
        if (!input.id.includes('select')) {
          applyFocusAndBlur(input)
        }
      })
    }
  })
}

const cleanModules = <T extends ObjectKeyType>(modulesRefs: ModulesRef<T>) => Object.values(modulesRefs).forEach((m: MutableRefObject<ModuleRef>) => m.current?.onClear())
const saveModules = <T extends ObjectKeyType>(modulesRefs: ModulesRef<T>) => Object.values(modulesRefs).map((m: MutableRefObject<ModuleRef>) => m.current.onSave())

export const FormModules = {
  getLazyRef,
  getModuleError,
  cleanModules,
  saveModules,
  handleFocusBlur,
}
