import { APIClient } from '@/services'
import { LoadPublicationProps } from '../../../components/Publication/Forms/usePublicationForm/types'

export function useLoadPublication(props: LoadPublicationProps) {
  const { isAuthor, isEditor, isReviewer, isPublisher } = APIClient.Session.useSession()
  const { data: publication, query: publicationQuery } = APIClient.Publications.useRetrieve({
    id: props?.article,
    queryOptions: { initialData: null, refetchOnWindowFocus: false },
  })
  const { data: flags } = APIClient.Flags.usePublicationFlags(props?.article)
  const possibleReviewers = APIClient.Publications.possibleReviewersManager.useList({
    filter: { article: props?.article, suggestion: true },
    queryOptions: { refetchOnWindowFocus: false },
  })
  const authors = APIClient.Publications.authorsManager.useList({
    filter: { article: props?.article },
    queryOptions: { refetchOnWindowFocus: false },
  })
  const mediaList = APIClient.Publications.mediaManager.useList({
    filter: { publication: props?.article },
    queryOptions: { refetchOnWindowFocus: false },
  })

  const isLoaded =
    (publicationQuery.isSuccess && !!publication) &&
    possibleReviewers.query.isSuccess &&
    authors.query?.isSuccess &&
    mediaList.query?.isSuccess

  return {
    isAuthor,
    isEditor,
    isReviewer,
    isPublisher,
    publication,
    publicationQuery,
    flags,
    possibleReviewers,
    authors,
    mediaList,
    isLoaded,
  }
}
