import { PossibleReviewer, Proposal } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { api } from '@/app'
import { queryClient } from '../../queryClient'
import { useManagerTable } from '@/utils'

const BASE_URL = 'article/proposals/authors/'

type ListFilterProps = {
    proposal: Proposal['id']
}

async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<PossibleReviewer>>(BASE_URL, {
    params,
  })

  return response.data
}

async function retrieve(id: PossibleReviewer['id'], proposal: number) {
  const response = await api.get<PaginationResponse<PossibleReviewer>>(`${BASE_URL}${id}/`, { params: { proposal }})

  return response.data
}

export const possibleAuthorsManager = new QueryManager<PossibleReviewer>({
  itemType: {} as PossibleReviewer,
  name: 'possibleAuthors',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFilterProps) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  retrieveItem: async ({ id, proposal }: Record<'id' | 'proposal', number>) => { //NOTE - this only receives ID, find a different way later
    if (!id || !proposal) return null
    const response = await retrieve(id, proposal)
    return response
  },

})

type UsePossibleAuthorsProps = Partial<UsePaginationTypes.PaginationParams> & {
  proposal: Proposal['id']
}
const limit = 15
export const usePossibleAuthors = (props: UsePossibleAuthorsProps) => {
  const {
    proposal,
    ...paginationProps
  } = props

  const [tableProps, possibleAuthors] = useManagerTable(possibleAuthorsManager, {
    filter: {
      ...paginationProps,
      proposal,
    },
    limit,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: 6000,
      },
    },
  })

  return {
    possibleAuthors,
    tableProps,
  }
}
