import { ProfileRoles, PublicationStatus } from "@/types";
import { bannerColors } from "./colors";
import { Settings } from "@/app";

export type IProgressBanner = typeof bannerColors[keyof typeof bannerColors] & {
  title: string
  body: string
  buttonText?: string
  onPress?: () => void
  linkText?: string
  linkUrl?: string
}

export const progressBannerStatus: { [x in keyof typeof PublicationStatus]?: { [x in ProfileRoles]?: IProgressBanner } } = {
  submitted_for_review: {
    author: {
      ...bannerColors.blue,
      title: 'Submitted for review',
      body: `Your manuscript has now been submitted, thank you.`,
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.blue,
      title: 'Submitted for review',
      body: 'Manuscript submitted – please review, assign another editor or invite reviewers.',
    },
    publisher: {
      ...bannerColors.blue,
      title: 'Submitted for review',
      body: 'Manuscript submitted – please review, assign another editor or invite reviewers.',
    },
  },

  revision_requested: {
    author: {
      ...bannerColors.yellow,
      title: 'Revision requested',
      body: 'Please revise your work by submitting a response to the reviewers as well as tracked and clean versions of your manuscript.',
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.yellow,
      title: 'Revision requested',
      body: 'Decision to revise sent to author, revision pending.',
    },
    publisher: {
      ...bannerColors.yellow,
      title: 'Revision requested',
      body: 'Decision to revise sent to author, revision pending.',
    },
  },

  revision_completed: {
    author: {
      ...bannerColors.blue,
      title: "Revision Submitted",
      body: "Thank you for submitting your revision, an Editor is evaluating it.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.blue,
      title: "Revision Submitted",
      body: "Please evaluate the revised manuscript, assign another editor, invite reviewers or make a decision."
    },
    publisher: {
      ...bannerColors.blue,
      title: "Revision Submitted",
      body: "Please evaluate the revised manuscript, assign another editor, invite reviewers or make a decision.",
    },
    reviewer: {
      ...bannerColors.blue,
      title: "Revision Submitted",
      body: "A revised manuscript has been submitted by the author.",
      linkText: "Instructions for Reviewers",
      linkUrl: Settings.ContactINFO.ReviewerInstructions,
    },
  },

  pending_review: {
    author: {
      ...bannerColors.orange,
      title: "Pending Review",
      body: 'Reviewers have been invited to review your manuscript, an Editor will make a decision when sufficient reviews have been submitted.',
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.orange,
      title: "Pending Review",
      body: "Reviewers invited, when sufficient reviews have been submitted, the status will change."
    },
    publisher: {
      ...bannerColors.orange,
      title: "Pending Review",
      body: "Reviewers invited, when sufficient reviews have been submitted, the status will change."
    },
  },

  under_review: {
    author: {
      ...bannerColors.blue,
      title: 'Under Review',
      body: "Initial reviews on your manuscript have been received, once sufficient reviews have been received a decision will be made.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
  },

  review_submitted: {
    author: {
      ...bannerColors.blue,
      title: "Required Reviews Completed",
      body: "Required reviews have now been submitted – a decision will be made soon.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.blue,
      title: "Required Reviews Completed",
      body: "Required reviews have now been completed, please make a decision."
    },
    publisher: {
      ...bannerColors.blue,
      title: "Required Reviews Completed",
      body: "Required reviews have now been completed, please make a decision."
    },
    reviewer: {
      ...bannerColors.blue,
      title: "Required Reviews Completed",
      body: "Required reviews have now been submitted for this manuscript.",
      linkText: "Instructions for Reviewers",
      linkUrl: Settings.ContactINFO.ReviewerInstructions,
    }
  },

  accepted: {
    author: {
      ...bannerColors.green,
      title: "Accepted",
      body: "No further action required for all users.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.green,
      title: "Accepted",
      body: "No further action required for all users."
    },
    publisher: {
      ...bannerColors.green,
      title: "Accepted",
      body: "No further action required for all users."
    }
  },
  rejected: {
    author: {
      ...bannerColors.red,
      title: "Rejected",
      body: "No further action required for all users.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.red,
      title: "Rejected",
      body: "No further action required for all users."
    },
    publisher: {
      ...bannerColors.red,
      title: "Rejected",
      body: "No further action required for all users."
    },
  },
  withdraw: {
    author: {
      ...bannerColors.darkBlue,
      title: "Withdrawn",
      body: "No further action required for all users.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.darkBlue,
      title: "Withdrawn",
      body: "No further action required for all users."
    },
    publisher: {
      ...bannerColors.darkBlue,
      title: "Withdrawn",
      body: "No further action required for all users."
    },
  },

  rejected_resubmit: {
    author: {
      ...bannerColors.red,
      title: "Reject and resubmit",
      body: "Your article has been rejected but a resubmission is encouraged following a substantial revision.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    }
  },

  rejected_transfer: {
    author: {
      ...bannerColors.red,
      title: "Reject and Transfer",
      body: "Your article has been rejected and transferred to a more appropriate journal.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.red,
      title: "Reject and Transfer",
      body: "This article has been rejected from the initial journal and transferred to a more appropriate journal."
    },
    publisher: {
      ...bannerColors.red,
      title: "Reject and Transfer",
      body: "This article has been rejected from the initial journal and transferred to a more appropriate journal."
    }
  },

  reviewers_invited_accepted: {
    author: {
      ...bannerColors.orange,
      title: "Reviewer(s) accepted invitation",
      body: "Reviewer(s) have accepted the invitation to review this manuscript.",
      linkText: "Instructions for Authors",
      linkUrl: Settings.ContactINFO.AuthorInstructions,
    },
    editor: {
      ...bannerColors.orange,
      title: "Reviewer(s) accepted invitation",
      body: "Reviewer(s) have accepted the invitation to review."
    },
    publisher: {
      ...bannerColors.orange,
      title: "Reviewer(s) accepted invitation",
      body: "Reviewer(s) have accepted the invitation to review."
    },
    reviewer: {
      ...bannerColors.orange,
      title: "Reviewer(s) accepted invitation",
      body: "Reviewer(s) have accepted the invitation to review.",
      linkText: "Instructions for Reviewers",
      linkUrl: Settings.ContactINFO.ReviewerInstructions,
    }
  },
}