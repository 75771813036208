import { variantProvider } from '@/app'
import { Image, Touchable, View, Text } from '@/components'

export type Flag = Record<'icon' | 'name', string> & { id: number }

type FlagProps = {
  flag: Flag
  showName?: boolean
  disabled?: boolean
  onPress?: (flag: Flag) => void
}

export const Flag = ({ flag, ...rest }: FlagProps) => {
    const handleFlagPress  = (flag, event: React.MouseEvent) => {
      event.stopPropagation()
      rest?.onPress?.(flag)
    }
  
  return (
    <View variants={['alignCenter', 'gap:0.75']}>
      <Touchable
        debugName="Flag icon"
        onClick={(event) => handleFlagPress(flag, event)}
        style={styles.flagWrapper}
        disabled={rest?.disabled}
      >
        <Image source={flag.icon} style={styles.icon}/>
      </Touchable>
      {rest?.showName ? <Text text={flag.name} variants={['p2', 'breakWord', 'color:neutral8']}/> : null}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  flagWrapper: {
    background: theme.colors.neutral1,
    /* @ts-ignore */
    borderRadius: theme.borderRadius.nanoish,
    ...theme.spacing.padding(0.25),
  },
  icon: { 
    width: 16, 
    height: 16 
  }
}), true)