import { AppStatus, useAppSelector } from '@/redux'
import { getFirebase } from '../../firebaseApp'
import { onUpdate, ReactQuery, TypeGuards, usePromise, useRef, useState } from '@codeleap/common'
import { retrieveCategory, retrieveSubcategory, retrieveType, retrieveRegion, retrieveScientificTag, retrieveArticleType } from '../publication'
import { retrieveEmailVariables } from '../publishers'
import { QueryKeys } from '../queryKeys'
import { Navigation, PermissionUtils, useToggleDevMode, useFirebaseUser } from '@/utils'
import { getOsAlert, IsDevEnv, LocalStorage } from '@/app'
import { queryClient } from '../queryClient'
import { APIClient } from '@/services'

import { Journal, Permission, Profile } from '@/types'
import { useProfile } from './useProfile'
import { retrieveGender, retrieveTitles } from '../profiles'
import { getSubmissionTerms } from '../articles'
import { useEmails } from '../email'
import { useFlags } from '../flags'

type AuthState = 'logged_in' | 'timeout' | 'pending'
type AuthStatePayload = {
  profile: Profile
  firebaseUser: any
  state: AuthState
}

const verify = (v) => !TypeGuards.isNil(v)

export const toggleRememberMe = async (rememberMe: boolean) => {
  const firebase = await getFirebase()
  const persistance = rememberMe ? firebase.auth.Auth.Persistence.SESSION : firebase.auth.Auth.Persistence.NONE
  firebase.auth().setPersistence(persistance)
}

export const useAuthState = () => {
  const { firebaseUser } = useFirebaseUser()
  const djangoUser = useProfile()?.data

  const [state, setState] = useState<AuthState>('pending')

  const authPromise = usePromise<AuthStatePayload>({
    debugName: 'AuthState',
    onResolve(payload) {
      setState(payload.state)
    },
    onReject() {
      setState('timeout')
    },
  })

  const isAuthenticated = !!firebaseUser?.uid && !!djangoUser?.id

  onUpdate(() => {
    let timeoutId: NodeJS.Timeout

    if (isAuthenticated) {
      authPromise.resolve({
        firebaseUser,
        profile: djangoUser,
        state: 'logged_in',
      })
      return
    } else {
      timeoutId = setTimeout(() => {
        authPromise.resolve({
          firebaseUser,
          profile: djangoUser,
          state: 'timeout',
        })
      }, 5000)
    }

    return () => {
      clearTimeout(timeoutId)
    }

  }, [djangoUser?.id, firebaseUser?.uid])

  return {
    isAuthenticated,
    firebaseUser,
    djangoUser,
    awaitAuth: authPromise._await,
    state: state,
  }
}

const DEBUG = IsDevEnv && false
const userHasSocialLogin = async () => {
  if (IsDevEnv && DEBUG) return { hasSocialLogin: true }

  const firebase = await getFirebase()

  const firebaseUser = firebase.auth().currentUser

  if (!firebaseUser) return { hasSocialLogin: false, signInMethod: 'password' }

  const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(firebaseUser?.email)

  const idTokenResult = await firebaseUser.getIdTokenResult()

  if (idTokenResult?.claims?.linkedin_id) {
    return { hasSocialLogin: true, signInMethod: 'linkedIn' }
  }

  const hasSocialLogin = signInMethods?.some(x => x !== 'password')

  return { hasSocialLogin, signInMethod: signInMethods[0] }
}

export async function checkSignupCompletion(data) {
  // const firebase = await getFirebase()

  // const firebaseUser = firebase.auth().currentUser

  // const { hasSocialLogin } = await userHasSocialLogin()

  // if (!!firebaseUser && (!firebaseUser?.emailVerified && data?.id === firebaseUser?.uid)) {
  //   if (!hasSocialLogin) {
  //     return {
  //       isComplete: false,
  //       nextStep: 'VerifyEmail',
  //       modal: 'verifyEmailBefore',
  //     }
  //   }
  // }

  const signupInfo = [
    data?.first_name,
    data?.last_name,
    data?.email,
  ]

  const hasCompletedSignup = signupInfo.every(verify)

  logger.debug('Signup completion', { signupInfo }, 'Auth')

  const steps = [
    ['signup', hasCompletedSignup],
  ] as const

  const nextStep = steps.find(x => !x?.[1])?.[0]

  const isComplete = steps.every(x => x?.[1])

  return {
    isComplete,
    nextStep,
  }
}

export const usePlagiarismEula = () => {
  const eula = ReactQuery.useQuery({
    queryKey: QueryKeys.plagiarismEula.key,
    queryFn: getSubmissionTerms,
    refetchOnMount: (q) => q.state.dataUpdateCount == 0,
    refetchOnWindowFocus: false,
    enabled: true,
  })
  return eula
}

export const useCategory = () => {
  const category = ReactQuery.useQuery({
    queryKey: QueryKeys.category.key,
    queryFn: retrieveCategory,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: true,
  })
  return category
}

export const useSubcategory = () => {
  const subcategory = ReactQuery.useQuery({
    queryKey: QueryKeys.subcategory.key,
    queryFn: retrieveSubcategory,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: true,
  })
  return subcategory
}

export const useArticleTypes = (title = '') => {
  const type = ReactQuery.useQuery({
    queryKey: QueryKeys.articleType.key(title),
    queryFn: () => retrieveArticleType(title),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: true,
  })
  return type
}

export const useFileCategories = () => {
  const file_categories = ReactQuery.useQuery({
    queryKey: QueryKeys.fileCategory.key,
    queryFn: retrieveType,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: true,
  })
  return file_categories
}

export const useRegions = (title = '') => {
  const regions = ReactQuery.useQuery({
    queryKey: QueryKeys.region.key(title),
    queryFn: () => retrieveRegion(title),
    refetchOnMount: (q) => q.state.dataUpdateCount == 0,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  return regions
}

export const useScientificTags = () => {
  const scientificTags = ReactQuery.useQuery({
    queryKey: QueryKeys.scientificTag.key,
    queryFn: retrieveScientificTag,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  return scientificTags
}

export const useEmailVariables = () => {
  const emailVariables = ReactQuery.useQuery({
    queryKey: QueryKeys.journalEmailVariables.key,
    queryFn: retrieveEmailVariables,
    refetchOnMount: (q) => q.state.dataUpdateCount == 0,
    refetchOnWindowFocus: false,
    enabled: !!QueryKeys.me.getData(),
  })

  return emailVariables
}

export const useGender = () => {

  const gender = ReactQuery.useQuery({
    queryKey: QueryKeys.gender.key,
    queryFn: retrieveGender,
    refetchOnMount: (q) => q.state.dataUpdateCount == 0,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  return gender
}

export const useTitle = () => {
  const title = ReactQuery.useQuery({
    queryKey: QueryKeys.title.key,
    queryFn: retrieveTitles,
    refetchOnMount: (q) => q.state.dataUpdateCount == 0,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  return title
}

export const useMyPermissions = (journal?: Journal['id']) => {

  const permissions = ReactQuery.useQuery({
    queryKey: QueryKeys.myPermissions.key(journal),
    queryFn: () => {
      if (!QueryKeys.me.getData()?.id) throw new Error('No profile id')
      return APIClient.Permissions.list<Permission[]>({
        journal,
      })
    },
    retry: 3,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0 || q.isStaleByTime(1000 * 60)
    },
    refetchOnWindowFocus: true,

  })

  return permissions
}

export function useSession(root = false) {
  const devMode = useToggleDevMode(root)

  const authFinished = useAppSelector(store => store.AppStatus.authFinished)
  const initialAuthResolved = useAppSelector(store => store.AppStatus.hasResolvedInitialAuth)
  const categories = useCategory()
  const plagiarismEula = usePlagiarismEula()
  const subcategories = useSubcategory()
  const file_categories = useFileCategories()
  const article_types = useArticleTypes()
  const regions = useRegions()
  const scientificTags = useScientificTags()
  const emailVariables = useEmailVariables()
  const genders = useGender()
  const titles = useTitle()
  const flags = useFlags()
  const emailTemplates = useEmails()
  const proposalEmailTemplates = APIClient.Proposals.useEmailVariables()

  const [hasFirebaseUser, setHasFirebaseUser] = useState(null)
  const profile = useProfile()
  const permissions = useMyPermissions()

  const logout = (props: Partial<Record<'withDone' | 'navigate' | 'showSplash', boolean>>) => {
    return new Promise<void>(async (resolve) => {
      if (props?.withDone) {
        AppStatus.set(props?.showSplash ? 'splash' : 'loading')
      }

      LocalStorage.clear()

      const firebase = await getFirebase()

      firebase.auth().signOut()
      queryClient.client.clear()
      AppStatus.clearModals()

      if (props?.withDone && !props?.showSplash) {
        AppStatus.set('done')
      }

      if (props?.navigate) {
        Navigation.navigate('Auth.Login')
      }
      resolve()
    })
  }

  const loginResolved = typeof profile.data !== 'undefined'

  const onOnboardingFinished = () => {
    AppStatus.authFinished()
  }

  const onInitialAuthResolved = () => {
    AppStatus.set('splash')

    setTimeout(() => {
      AppStatus.set('idle')

      AppStatus.initialAuthResolved()
    }, 3500)
  }

  const requestPasswordReset = async (email: string) => {
    await AppStatus.set('loading')

    try {
      const firebase = await getFirebase()
      await firebase.auth().sendPasswordResetEmail(email)

      setTimeout(() => {
        AppStatus.set('done')
      }, 2000)
      return true
    } catch (e) {
      await AppStatus.set('idle')
      console.error('Password reset', e, 'Auth')
      getOsAlert('passwordResetError')
      throw e
    }

  }

  const reauthenticate = async (password: string) => {
    const firebase = await getFirebase()

    return firebase.auth().signInWithEmailAndPassword(profile.data.email, password)
  }

  onUpdate(() => {
    if (loginResolved && root && !initialAuthResolved) {
      onInitialAuthResolved()
    }
  }, [loginResolved])

  const initialRole = useRef(null)

  if (!!profile?.data?.current_role && !initialRole.current) {
    initialRole.current = profile?.data?.current_role
  }

  const roleHasChanged = initialRole.current !== profile?.data?.current_role

  if (roleHasChanged) {
    setTimeout(() => {
      initialRole.current = profile?.data?.current_role
    })
  }

  return {
    profile: profile.data,
    categories: categories.data,
    subcategories: subcategories.data,
    article_types: article_types.data,
    regions: regions.data,
    scientificTags: scientificTags.data,
    plagiarismEula: plagiarismEula.data,
    ...PermissionUtils.getRoleBooleans(profile.data?.current_role),
    reauthenticate,

    logout,
    isLoggedIn: !!profile.data,
    loginResolved,
    authFinished,
    file_categories,
    onOnboardingFinished,
    requestPasswordReset,
    warnEmailInUse: () => getOsAlert('emailInUseError'),
    profileQuery: profile,
    hasFirebaseUser,
    permissions,
    roleHasChanged,
    emailVariables: emailVariables.data,
    genders: genders.data,
    titles: titles.data,
    flags: flags.data,
    emailTemplates,
    proposalEmailTemplates,
  }
}
