import { Proposal, ProposalStatusBooleans, ProposalStatus } from '@/types'
import { formatFileName } from './misc'
import { capitalize, TypeGuards } from '@codeleap/common'

export const fieldAnchors = {
  proposalFields: 'proposal-anchor',
  emailTemplateFields: 'email-template-anchor',
}

export const previewTitle = (proposal: Proposal) => proposal?.title || formatFileName(proposal?.file)

export const getProposalBooleans = (status: Proposal['status']) => {
  const statusObject: ProposalStatusBooleans = {} as ProposalStatusBooleans
  Object.keys(ProposalStatus).forEach((k) => (statusObject[`is${capitalize(k)}`] = status === k))

  return statusObject
}

export const verifyProposalChanges = (proposal: Proposal, ...args: any[]) => {
  if (!proposal) return {}
  const changes = args.reduce((acc, cur) => ({ ...acc, ...cur }), {})

  return Object.entries(changes).reduce((acc, [key, val]) => {
    const value = proposal?.[key]

    if (TypeGuards.isArray(val) && val.every((v) => value?.includes(v)) && val.length === value.length) return acc
    if (JSON.stringify(value) === JSON.stringify(val) || value === val) return acc
    if (TypeGuards.isNil(value) && TypeGuards.isNil(val)) return acc
    else return { ...acc, [key]: val }
  }, {})
}
