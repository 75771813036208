import { variantProvider } from '@/app'
import { Button, View, Text, HTMLRender } from '..'
import { InvitationReviewer, Publication } from '@/types'
import { AppStatus } from '@/redux'
import { modal, ModalFlowProps } from '@/utils'

type PreviewInviteProps = {
  publication: Publication['id']
  users?:Partial<InvitationReviewer>[]

  canGoBack?: boolean
  full_name?: string
  email?: string
  message?: string
  subject?: string
  isManual?: boolean
  description?: string
}

export const PreviewInviteModal = modal<PreviewInviteProps>().content((props) => {

  const { visible, toggle, nextOrToggle, previousOrToggle, flow, request, ...data } = props as ModalFlowProps<'invite', PreviewInviteProps>

  const handleSubmit = async () => {
    AppStatus.set('loading')
    try {
      if (data?.isManual) {
        const { full_name, email } = data
        request.resolve({ ...data, users: [{ full_name, email: email?.toLowerCase() }] })
      } else {
        request.resolve(data)
      }

    } catch (e) {

    } finally {
      AppStatus.set('done')
    }
  }

  return (

    <View variants={['column', 'fullWidth', 'gap:4']}>
      {data?.description ? <Text text={data.description} variants={['p2', 'color:neutral7']}/> : null}
      {data?.subject ? <View variants={['column', 'gap:1']}>
        <Text variants={['p2', 'color:neutral7']} text='Subject' />
        <Text variants={['p1', 'color:neutral8']} text={data.subject} />
      </View> : null}

      {data?.email || data?.users?.length ? <View variants={['column', 'gap:1']}>
        <Text variants={['p2', 'color:neutral7']} text='Email' />
        {!data?.isManual ? data?.users?.map(r => <Text variants={['p1', 'color:neutral8']} text={r?.email}/>) : <Text variants={['p1', 'color:neutral8']} text={data?.email} />}
      </View> : null}

      <View variants={['column', 'gap:1']}>
        <Text variants={['p2', 'color:neutral7']} text='Message' />
        <HTMLRender html={data?.message} />
      </View>
      <View variants={['gap:1', 'center']}>
        {data?.canGoBack ? (
          <Button
            debugName='Back to Invite Reviewer modal'
            text='Continue editing'
            variants={['large', 'border-radius:tiny', 'flat']}
            onPress={() => previousOrToggle({
              message: data?.message,
              subject: data?.subject,
              email: data?.email,
              full_name: data?.full_name,
            })}
          />) : null}
        <Button
          debugName='Send invite modal'
          text='Confirm Invite'
          icon='send'
          variants={['large', 'border-radius:tiny']}
          onPress={handleSubmit}/>
      </View>
    </View>

  )
})

const MODAL_WIDTH = 720

const styles = variantProvider.createComponentStyle(() => ({
  box: {
    width: MODAL_WIDTH,
  },
}), true)

PreviewInviteModal.props({
  styles: {
    box: styles.box,
  },
  title: 'Email preview',
})
