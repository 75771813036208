import { Proposal } from '@/types'
import { api } from '@/app'
import { ReactQuery } from '@codeleap/common'

const BASE_URL = 'article/proposals/'

type StatsReportParams = { proposalId: Proposal['id'] }

export async function getStatsReport({ proposalId }: StatsReportParams) {
  const response = await api.get(`${BASE_URL}${proposalId}/stats/`)

  return response.data
}

export const useProposalStats = ({ id, automaticInvitation }: { id: Proposal['id']; automaticInvitation: boolean }) => {

  const query = ReactQuery.useQuery({
    queryKey: ['proposalStats', id],
    queryFn: () => getStatsReport({ proposalId: id }),
    enabled: !!id,
    refetchInterval: (q) => {
      if (automaticInvitation) return 3000
      if (!!q?.url) return false
      return 5000
    },
  })

  const url = query?.data?.url
  const isLoading = automaticInvitation
    ? url
      ? false
      : query.data?.status === 'processing' || query.isLoading
    : query.data?.status === 'processing' || query.isLoading

  return {
    url,
    isLoading,
  }
}
