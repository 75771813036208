import { AppForms } from '@/app'
import { View, TextInput, useProposalForm } from '@/components'
import { ProposalUtils, useMemoizedForm } from '@/utils'
import { deepEqual, TypeGuards, useCallback, useImperativeHandle, useMemo } from '@codeleap/common'

export const ArticleForm = () => {
  const isProposalEditable = useProposalForm(v => v.isProposalEditable)
  const proposal = useProposalForm(v => v.proposal)
  const ref = useProposalForm(v => v.modulesRefs.proposal_details)

  const initialState = useMemo(() => ({
    manuscript_title: proposal?.manuscript_title,
    keywords: TypeGuards.isArray(proposal.keywords) ? proposal.keywords.join(', ') : '',
    journal_name: proposal?.journal_name,
  }), [proposal])

  const form = useMemoizedForm(AppForms.editProposal, { validateOn: 'change', initialState })

  const handleKeywords = useCallback(() => {
    const { keywords = '' } = form.values
    return Array.from(new Set(keywords.split(/[,.;_/\\|]/).map(i => i.trim()))).sort()
  }, [form.values.keywords])

  const onClear = () => {
    form.setFormValues({
      manuscript_title: '',
      keywords: '',
      journal_name: '',
    })
  }

  const onSave = () => {
    const keywords = handleKeywords()

    const proposalFormData = {
      keywords: keywords,
      manuscript_title: form.values.manuscript_title?.trim(),
      journal_name: form.values.journal_name?.trim(),
    }

    return ProposalUtils.verifyProposalChanges(proposal, proposalFormData)
  }

  const onValidate = () => {
    const fieldsToValidate = ['manuscript_title', 'keywords', 'journal_name'] as const

    return {
      isValid: form?.validateMultiple?.(fieldsToValidate)?.valid,
      anchor: ProposalUtils.fieldAnchors.proposalFields,
    }
  }

  useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    showError: () => form.validateAll(true),
    hasChanges: !deepEqual(form.values, initialState),
  }))

  const handleForm = useCallback(field => {
    const fieldFormError = form.fieldErrors[field]
    const fieldValidation = form.validateField(field)

    return {
      ...form.register(field),
      disabled: !isProposalEditable,
      _error: fieldFormError && !fieldValidation.valid ? fieldValidation.message ?? fieldFormError : '',
    }
  }, [form])

  return (
    <View variants={['gap:2', 'column']}>
      <TextInput {...handleForm('manuscript_title')} />
      <TextInput {...handleForm('journal_name')} />

      <TextInput
        {...handleForm('keywords')}
        variants={['fullWidth', 'resizable:multiline']}
      />
    </View>
  )
}
