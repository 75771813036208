import { APIClient } from '@/services'
import { Proposal } from '@/types'

export function useLoadProposal(props: {proposalId: Proposal['id']}) {
  const { data: proposal, query: proposalQuery } = APIClient.Proposals.useRetrieve({
    id: props?.proposalId,
    queryOptions: { initialData: null, refetchOnWindowFocus: false, refetchInterval: (data, query) => {
      if (data?.status === 'indexing') return 2500
      return false
    } },
  })
  const isLoaded = proposalQuery.isSuccess && !!proposal && proposal?.status !== 'indexing'

  return {
    proposal,
    proposalQuery,
    isLoaded,
  }
}
