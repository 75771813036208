export const authorItems = [
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
    icon: 'license',
  },
  {
    text: 'Journals',
    to: 'journals/',
    icon: 'book',
  },
  {
    text: 'Archive',
    to: 'archive/',
    icon: 'archive',
  },
]

export const editorItems = [
  {
    text: 'In Tray',
    to: 'in_tray/',
    icon: 'in-tray',
  },
  {
    text: 'eGenie',
    to: 'egenie/',
    icon: 'bulb-brain',

  },
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
    icon: 'license',
  },
  {
    text: 'Archive',
    to: 'archive/',
    icon: 'archive',
  },
  {
    text: 'Journals',
    to: 'journals/',
    icon: 'book',
  },
]

export const publisherItems = [
  {
    text: 'In Tray',
    to: 'in_tray/',
    icon: 'in-tray',
  },
  {
    text: 'eGenie',
    to: 'egenie/',
    icon: 'bulb-brain',

  },
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
    icon: 'license',
  },
  {
    text: 'Archive',
    to: 'archive/',
    icon: 'archive',
  },
  {
    text: 'Dashboard',
    to: 'dashboard/',
    icon: 'chart-histogram',
  },
  {
    text: 'Journals',
    to: 'journals/',
    icon: 'book',
  },
  {
    text: 'Permissions',
    to: 'permissions/',
    icon: 'key-round',
  },
  // {
  //   text: 'Invites',
  //   to: 'invites/',
  // },
]

export const reviewerItems = [
  {
    text: 'Invites',
    to: 'invites/',
    icon: 'mail',
  },
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
    icon: 'license',
  },
]
