import { FilePreview } from './Forms/FilePreview'
import { PublicationHeader } from './PublicationHeader'
import { PublicationToast } from './PublicationToast'
import { PublicationHistory, historyMessages } from './History'
import { PublicationBanner } from './PublicationBanner'
import { PlagiarismChecker } from './Plagiarism'

export const Publication = {
  FilePreview,
  Header: PublicationHeader,
  Toast: PublicationToast,
  History: PublicationHistory,
  Banner: PublicationBanner,
  PlagiarismChecker,
}

export * from './Forms/usePublicationForm'
export * from './Forms/PublicationForm'
export { historyMessages }
