import { ActionIcon, ActivityIndicator, Button, ExpandableView, TextEditor, ReviewsOverview, Text, View } from '@/components'
import { React, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { downloadFileFromURL, formatFileName, humanizedFileSize, modal, useFileCategoryOptions } from '@/utils'
import { PlagiarismChecker } from '../Publication/Plagiarism'
import { Publication } from '@/types'

type ReplicaOverviewModalProps = { publicationId?: Publication['id']; version?: number }

export const ReplicaOverviewModal = modal<ReplicaOverviewModalProps>({ independent: true }).content((props) => {
  const { publicationId, version, toggle } = props

  const { isEditor, isPublisher } = APIClient.Session.useSession()

  const publication = APIClient.Publications.useRetrieve({
    id: publicationId,
  })

  const file_categories = useFileCategoryOptions({ version: version, status: publication?.data?.status })

  const replicaVersion = APIClient.Replica.replicaManager.useRetrieve({
    filter: {
      version,
    },
    id: publicationId,
  })

  const versionId = replicaVersion?.data?.results?.[0]?.id

  const manuscriptFormMedia = APIClient.Publications.mediaManager.useList({
    filter: { publication: publicationId },
    queryOptions: { refetchOnWindowFocus: false, enabled: version === 1 },
  })

  const replicaMedia = APIClient.Replica.replicaMediaManager.useList({
    filter: {
      reply: versionId,
    },
    queryOptions: {
      enabled: !!versionId,
    },
  })

  const loadingComment = replicaVersion.query.isLoading
  // on the first version we need to show the main manuscript + the media added on te form, reply justt returns the main manuscript in this case
  // we would need a refactor on the backend to fix that
  const media = [...(replicaMedia?.items || []), ...(version === 1 ? manuscriptFormMedia?.items || [] : [])]
  const comment = replicaVersion?.data?.results?.[0]?.comment

  const isLatestVersion = publication.data?.version === version
  const canSubmitDecision = (isEditor || isPublisher) && isLatestVersion

  const AttachmentItem = ({ item }) => {

    const filename = formatFileName(item?.file)
    const currentCategory = file_categories?.find(cat => cat?.value === item?.file_category)

    return (
      <View variants={['fullWidth', 'justifySpaceBetween', 'alignCenter', 'padding:1', 'borderBottom', 'gap:1']}>
        <Text variants={['p1', 'color:neutral8', 'ellipsis', 'breakWord', 'marginRight:auto']} style={{ maxWidth: 330, minWidth: 330 }} text={filename} />
        <View variants={['fullWidth', 'marginLeft:auto', 'textLeft', 'marginLeft:auto']} style={{ minWidth: 200, maxWidth: 200 }}>
          <Text variants={['p1', 'ellipsis']} text={currentCategory?.title || ''} />
        </View>

        <View variants={['gap:4', 'alignCenter', 'justifySpaceBetween']} style={{ maxWidth: 140, minWidth: 140 }}>
          <Text variants={['p1', 'color:neutral8', 'marginLeft:auto']} text={humanizedFileSize(item?.file_size)} />
          <ActionIcon
            icon={'download'}
            variants={['transparent', 'neutral10', 'iconSize:2']}
            onPress={() => downloadFileFromURL({ file: item?.file })}
            debugName={`Download file ${item?.file_name} `}
          />
        </View>
      </View>
    )
  }

  const Comment = () => {

    if (loadingComment) {
      return (
        <View style={styles.commentWrapperScroll} variants={['center']}>
          <ActivityIndicator debugName={'Loading Indicator - Review Comment'} />
        </View>
      )
    }

    return (
      <View style={styles.commentWrapperScroll}>
        {comment ?
          <TextEditor value={comment} disabled />
          : <Text variants={['p3', 'color:neutral8']} text='No comments provided' />}
      </View>
    )
  }

  return (
    <>
      <View variants={['column', 'fullWidth', 'gap:4']}>
        <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}>
          <Text variants={[`h3`]} text={`Manuscript version ${version}`} />
          <ActionIcon
            icon='x'
            debugName='Manuscript Review Overview Modal - close modal icon on press'
            variants={['filterIcon', 'primary5', 'iconSize:3']}
            onPress={toggle}
          />
        </View>

        {version > 1 ? (
          <View variants={['fullWidth', 'backgroundColor:neutral2', 'padding:2', 'column', 'border-radius:small']}>
            <Text variants={[`p2`, 'marginBottom:1', 'color:neutral8', 'semiBold']} text={`Author comments`} />
            <Comment />
          </View>
        ) : null}

        <View variants={['fullWidth', 'column', 'gap:4']}>
          <ExpandableView title='Attachments' initialState>
            <View style={styles.scroll}>
              {media?.map?.((item) => <AttachmentItem key={item?.id} item={item} />)}
            </View>
          </ExpandableView>

          <ExpandableView title='Plagiarism check' initialState>
            <PlagiarismChecker id={publication?.data.id} />
          </ExpandableView>

        </View>
      </View>

      {canSubmitDecision && (
        <Button
          text='Submit decision'
          icon='eyeglass-2'
          debugName='Open decision from version modal'
          variants={['large', 'marginHorizontal:auto', 'marginTop:auto']}
          onPress={() => {
            toggle()
            ReviewsOverview.open()
          }}
        />
      )}
    </>
  )
})

ReplicaOverviewModal.props({
  variants: ['centered', 'replicaOverviewModal'],
  styles: {
    box: {
      maxHeight: '97vh',
    },
  },
  showClose: false,
})

const styles = variantProvider.createComponentStyle((theme) => ({
  commentWrapperScroll: {
    minHeight: 150,
    maxHeight: 180,
    overflowY: 'auto',
    [theme.media.down('xxlarge')]: {
      maxHeight: 150,
    },
    [theme.media.down('mid')]: {
      maxHeight: 100,
    },
  },
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.spacing.paddingRight(1),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: 150,
    maxHeight: 260,
    [theme.media.down('xxlarge')]: {
      maxHeight: 200,
    },
    [theme.media.down('mid')]: {
      maxHeight: 170,
    },
  },
}), true)
