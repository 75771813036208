import { Profile } from './profile'

export enum AuthorInvitationStatusObj {
  pending = 'pending',
  sent = 'sent',
  accepted = 'accepted',
  denied = 'denied',
}

export type InvitationAuthor = Pick<Profile,
  'citations' |
  'interests' |
  'email' |
  'full_name' |
  'link' |
  'affiliations' |
  'website' |
  'organisation' |
  'tags'> & {
  id: number
  articles: string[]
  status?: keyof typeof AuthorInvitationStatusObj
  status_datetime?: string
  created_datetime: string
  found_datetime?: string
  author_id: string
  keywords: string[]
  is_internal: boolean
  found_through?: 'manuscripts' | ''
  profile?: Profile
}

export type AuthorAnswer = {
  proposal: string | number
  author: string | number
  ts: string
  answer: 'accept' | 'deny'
}
