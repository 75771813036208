import { api } from '@/app'
import { ReactQuery } from '@codeleap/common'
import { QueryKeys } from '..'

const BASE_URL = 'article/proposals/email_variables/'

export async function retrieveEmailVariables() {
  const response = await api.get<{[x in string]: string}>(BASE_URL)

  return response.data
}

export const useEmailVariables = () => {
  const emailVariables = ReactQuery.useQuery({
    queryKey: QueryKeys.proposalEmailVariables.key,
    queryFn: retrieveEmailVariables,
    refetchOnMount: (q) => q.state.dataUpdateCount == 0,
    refetchOnWindowFocus: false,
    enabled: !!QueryKeys.me.getData(),
  })

  return emailVariables
}
