import { React, variantProvider } from '@/app'
import { View, Text, Switch, Icon } from '@/components'
import { SwitchProps } from '@codeleap/web'

export type AutomaticInvitationProps = {
  title: string
  description: string
  switchProps: Partial<SwitchProps>
}

export const AutomaticInvitation = ({ title, description, switchProps }: AutomaticInvitationProps) => {
  return (
    <View variants={['column', 'gap:1', 'flex']}>
      <Text
        text={title}
        variants={['p2', 'color:neutral8']}
        style={styles.switchText}
      />
      <Text
        text={description}
        variants={['p4', 'regular', 'color:neutral7']}
        style={styles.switchText}
      />
      <View variants={['row', 'fullWidth', 'alignCenter', 'wrap', 'justifySpaceBetween']}>
        <Text variants={['row', 'noWrap', 'p1', 'neutral-8', 'alignCenter', 'justifyCenter']}>
          <Icon name='eneural' variants={['small', 'marginRight:1']} debugName='eNeural Icon' />
          <Text variants={['h4', 'noWrap']} text='eNeural Engine &reg;'/>
          {'\u00A0'}- Automatic invitations
        </Text>
        <Switch
          debugName='Automatic invitation switch'
          variants={['large', 'automaticInvite']}
          styles={{
            label: styles.switchLabel,
          }}
          {...switchProps}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  switchLabel: {
    display: 'none',
  },
  switchText: {
    color: theme.colors.neutral7,
  },
}), true)
