import { Journal } from './journal'
import { Profile } from './profile'
import { Publisher } from './publisher'

export enum ProposalStatus {
  indexing = 'indexing', // Finding keywords
  pending = 'pending', // Keywords indexed but proposal was not submitted yet
  searching = 'searching', // Searching for authors after proposal submission
  inviting = 'inviting', // One or less than 500 authors have been invited, and none answered
  accepted = 'accepted', // One or more authors have accepted
  denied = 'denied', // Invitation limit reached and all authors have denied
  unanswered = 'unanswered', // Invitation limit reached and no authors accepted while some have not answered
}

type Section = {
  index: number
  text: string
}

export type ProposalSections = {
  [x in string]: Section;
}

export type Proposal = {
  id: number
  profile: Pick<Profile, 'id' | 'organisation'> & {name: string}
  status?: keyof typeof ProposalStatus
  submitted: boolean
  keywords: string[]
  auto_invite: boolean
  publisher: Publisher['id']
  journal: Journal['id']
  journal_logo: Journal['image']
  journal_name: Journal['title']
  manuscript_title: string
  proposal: string // email variable
  email_subject: string
  email_body: string
  created_datetime: string
  updated_datetime: string
  status_datetime: string
  file: string
  stats_file?: string

  accepted_count: number
  denied_count: number
  invited_count: number
  pending_count: number
  total_count: number
}

export type ProposalStatusBooleans = {
  [key in `is${Capitalize<keyof typeof ProposalStatus>}`]: boolean;
}
