import { APIClient } from '@/services'
import { Table, View, Text, ActionIcon, historyMessages, usePublicationForm } from '@/components'
import { DateUtils, useManagerTable } from '@/utils'
import { PublicationHistory as IPublicationHistory } from '@/types'
import { Badge } from '@codeleap/web'
import { AnyFunction, TypeGuards } from '@codeleap/common'

function f() {}
export type PublicationHistoryProps = {
  setCurrentTab: AnyFunction
}

export const PublicationHistory = (props: PublicationHistoryProps) => {
  const { setCurrentTab } = props

  const refetchPublication = usePublicationForm(v => v.refetchPublication)
  const publication = usePublicationForm(v => v.publication)

  const { profile } = APIClient.Session.useSession()

  const badges = APIClient.Badges.useBadges(profile)

  const [tableProps, history] = useManagerTable(APIClient.Publications.publicationHistoryManager, {
    filter: {
      article: publication?.id,
    },
  })

  const _historyMessages = historyMessages()

  const onRowPress = async (item = {}) => {
    const currentReply = item?.reply && item?.reply[item?.reply?.length - 1]
    const { onPress = null, shouldReload = false } = _historyMessages[item?.changes?.type]

    if (TypeGuards.isFunction(onPress)) {
      const promises: Promise<any>[] = [
        onPress({
          reply: currentReply?.id,
          article: publication,
          publicationID: publication.id,
          setCurrentTab,
          ...item,
        }).catch(f),
      ]
      if (shouldReload) {
        promises.push(
          history.query.refetch().catch(f),
          refetchPublication().catch(f),
          badges.refresh().catch(f),
          tableProps.reset().catch(f),
        )
      }
      await Promise.all(promises)
    }
  }

  return (
    <Table
      {...tableProps}
      debugName='History List'
      onRowPress={onRowPress}
      showPlaceholder
      placeholderProps={{ icon: 'history', variants: ['table'], title: 'No records found.' }}
      columnMap={[
        {
          label: 'HISTORY RECORDS',
          component: (item: IPublicationHistory) => {
            const rowData = _historyMessages[item?.changes?.type]

            return (
              <View
                variants={[
                  'fullWidth',
                  'gap:1',
                  'alignCenter',
                  'justifySpaceBetween',
                  'row',
                  'paddingHorizontal:2',
                  'relative',
                ]}
              >
                <View variants={['alignCenter', 'row', 'gap:1']}>
                  <ActionIcon
                    debugName='PublicationHistory:Item:Icon'
                    name={rowData?.icon(item)}
                    variants={['neutral2', 'historyIcon']}
                  />
                  {rowData?.name(item)}
                </View>
                <Text
                  text={DateUtils.formatDate(item?.created_datetime, 'dd/MM/yy, h:mm a')}
                  variants={['neutral-6', 'noWrap']}
                />
                {item?.badges?.length ? (
                  <Badge variants={['border', 'position:leftTop']} style={{ left: 8 }} badge />
                ) : null}
              </View>
            )
          },
          mainColumn: true,
        },
      ]}
    />
  )
}
