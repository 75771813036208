import { APIClient } from '@/services'
import { useSession } from './useSession'
import { Profile } from '@/types'

export function useDeleteAccount() {

  const { logout } = useSession()

  const onDeleteAccount = (id: Profile['id']) => {
    logout({navigate: true, showSplash: true})
    APIClient.Session.deleteProfile(id)
  }

  return {
    onDeleteAccount,
  }
}
