import { React, variantProvider } from '@/app'
import { View, ActionIcon, Text, Icon, Touchable, Image, Button, openCropPicker, FileInput } from '@/components'
import { FileUtils } from '@/utils'
import { useMemo, useState } from '@codeleap/common'
import { useFileInput } from '@codeleap/web'

type PicturePickerProps = {
  label?: string
  description?: string
  size: Record<'width' | 'height' | 'aspect', number>
  initialImage?: string
  disabled?: boolean
  onPickImage?: (params: { preview: string; file: File }) => void
  onDeleteImage?: () => void
}

export const PicturePicker = (props: PicturePickerProps) => {
  const {
    label,
    description,
    size,
    initialImage,
    disabled,
    onPickImage: _onPickImage,
    onDeleteImage: _onDeleteImage,
  } = props
  const [_image, setImage] = useState<string>()
  const fileInput = useFileInput()
  const { width: WIDTH, height: HEIGHT, aspect } = size

  const onPickImage = async () => {
    const files = await fileInput.openFilePicker()

    if (!files?.length) return

    const [f] = files

    const croppedFile = await openCropPicker(f.file, {
      aspect,
      ruleOfThirds: true,
      description: description,
    })

    if (!croppedFile) return

    const { compressedBase64Image, newFile } = await FileUtils.compressAndValidateImage(croppedFile.file)

    if (!compressedBase64Image && !newFile) return

    setImage(compressedBase64Image)
    _onPickImage?.({ preview: compressedBase64Image, file: newFile })
  }
  const onDeleteImage = () => {
    setImage('')
    _onDeleteImage?.()
  }

  const handlePickImage = () => {
    onPickImage()
  }

  const _styles = useMemo(() => {
    return {
      imageWrapper: {
        height: HEIGHT,
        minWidth: WIDTH,
        maxWidth: WIDTH,
        maxHeight: HEIGHT,
        minHeight: HEIGHT,
      },
      touchableWrapper: {
        height: HEIGHT,
      },
    }
  }, [size])

  return (
    <View>
      <View variants={['column', 'gap:1', 'alignStart', 'justifyStart']}>
        <View variants={['column']}>
          {label ? <Text text={label} variants={['p2']} /> : null}
          {description ? <Text text={description} variants={['p4', 'marginTop:0.5']} /> : null}
        </View>
        <Touchable debugName={'PicturePicker touchable'} onPress={handlePickImage} disabled={disabled} css={[styles.touchableWrapper, _styles.touchableWrapper]}>
          <View css={[styles.picture, _styles.imageWrapper]}>
            {!!_image || initialImage ? (
              <Image source={_image || initialImage} css={styles.image} />
            ) : (
              <Icon debugName='createStory:plus' name={'plus'} variants={['primary', 'size:4']} />
            )}
          </View>
          <ActionIcon
            debugName={`Picture picker`}
            onPress={handlePickImage}
            icon='camera'
            style={styles.actionIcon}
            variants={['flat', 'border-radius:rounded']}
            disabled={disabled}
          />
        </Touchable>

        {!!_image ? (
          <Button
            debugName='PicturePicker:delete'
            text='Delete'
            variants={['flat', 'flat:destructive', 'marginTop:2']}
            onPress={onDeleteImage}
          />
        ) : null}
      </View>

      <FileInput ref={fileInput.ref} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    touchableWrapper: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    picture: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: theme.colors.primary1,
      borderRadius: theme.borderRadius.tiny,
      ...theme.presets.center,
    },
    image: {
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      // objectFit: 'contain',
    },
    actionIcon: {
      position: 'absolute',
      bottom: -8,
      right: -8,
      ...theme.effects.elevated,
    },
  }),
  true,
)
