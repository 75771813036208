import { AppForms } from '@/app'
import { APIClient } from '@/services'
import { modal } from '@/utils'
import { useForm } from '@codeleap/common'
import { Button, TextInput, View } from '..'

export const ImpersonationModal = modal({ independent: true }).content(({ toggle }) => {
  const impersonate = APIClient.Session.useImpersonation()

  const form = useForm(AppForms.impersonation)

  const onImpersonate = () => {
    toggle()
    impersonate(form.values.email)
  }

  return <View variants={['column', 'gap:2']}>
    <TextInput
      {...form.register('email')}
      label=''
      description='Enter the email below to login as the specified user'
      variants={['flex']}
      debugName='Impersonate'
    />
    <Button
      debugName='Impersonate'
      text={'Confirm'}
      disabled={!form.isValid}
      onPress={onImpersonate}
    />
  </View>

}).props({
  title: 'Proxy',
})
