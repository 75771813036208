import { View, Text } from '@/components'
import { variantProvider } from '@/app'

export type TypeColumnProps = { label: string }

export const TypeColumn = ({
  label,
}: TypeColumnProps) => (
  <View css={styles.wrapper}>
    <Text
      text={label}
      variants={['p1', 'color:neutral8']}
      css={[styles.articleTypeColumn]}
    />
  </View>
)

const LINE_HEIGHT = 18
const MAX_LINES = 3

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: 200,
    ...theme.presets.flex,
    ...theme.presets.center,
    ...theme.presets.fullHeight,
    ...theme.spacing.gap(0.5),
    ...theme.presets.column,
  },
  articleTypeColumn: {
    whiteSpace: 'normal',

    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    lineHeight: LINE_HEIGHT + 'px',
    maxHeight: LINE_HEIGHT * MAX_LINES,
    ...theme.spacing.margin(0),

    WebkitLineClamp: MAX_LINES,
    WebkitBoxOrient: 'vertical',

    overflow: 'hidden',
    textOverflow: 'ellipsis',

  },
}), true)
