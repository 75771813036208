import { onMount, onUpdate, useMemo, useState } from '@codeleap/common'
import { Theme, variantProvider } from '@/app'
import { View, ActionIcon, FlagDetail, Text } from '@/components'
import { FlagFlow } from '../Modals/flows'

import { Flag } from './Flag'
import { FlagWithComment, Publication } from '@/types'

type TypeList = { type: 'list', flags?: Flag[] }
type TypeDetail = { type: 'detail', flags?: FlagWithComment[] }
export type FlagsListProps = (TypeList | TypeDetail) & {
  disabled?: boolean
  compact?: boolean
  manuscriptId?: Publication["id"]
  showAdd?: boolean
  onChangeFlag?: (f: FlagWithComment[]) => void
}

const max = {
  [Theme.breakpoints.huge]: 24,
  [Theme.breakpoints.xxlarge]: 9,
  [Theme.breakpoints.xlarge]: 3,
}

const updateMaxFlags = () => {
  const width = window?.innerWidth
  if (width >= Theme.breakpoints.huge) return max[Theme.breakpoints.huge]
  else if (width >= Theme.breakpoints.xxlarge) return max[Theme.breakpoints.xxlarge]
  else return max[Theme.breakpoints.xlarge]
}

export const FlagsList = (props: FlagsListProps) => {
  const isDetail = props?.type === 'detail'

  const { flags = [], hasMoreFlags } = useMemo(() => {
    const flags = isDetail ? props?.flags?.map(f => f.flag) : props?.flags
    const maxFlags = props?.compact ? updateMaxFlags() : flags?.length
    const currFlags = flags?.slice(0, maxFlags)
    const hasMoreFlags = props?.flags?.length - currFlags?.length

    return { flags: currFlags, hasMoreFlags }
  }, [props?.compact, props?.flags, isDetail, props?.disabled])
  
  async function handleAdd(e: React.MouseEvent) {
    e.stopPropagation()
    /*@ts-ignore*/
    const newFlags = await FlagFlow.request({ flags: flags, manuscriptId: props?.manuscriptId }) as FlagWithComment[]
    props?.onChangeFlag?.(newFlags)
  }

  return (
    <View
      variants={['fullWidth', 'row', 'wrap', 'gap:0.5', 'alignEnd']}
      style={{...styles.listWrapper, ...(props?.compact ? styles.compactWrapper : {})}}
    >
      {props?.showAdd ? (
        <ActionIcon
          debugName="Add flag icon"
          onClick={(e) => handleAdd(e)}
          icon="plus-circle"
          variants={['iconSize:1']}
          styles={{ touchableWrapper: styles.addBtnWrapper }}
        />
      ) : null}

      {flags?.map((flag) => (
        <Flag key={flag.id} flag={flag} onPress={() => FlagDetail.open({ flags: [props?.flags as unknown as FlagWithComment], manuscriptId: props?.manuscriptId, disabled: true })} />
      ))}

      {!!hasMoreFlags ? <Text text={`+${hasMoreFlags}`} variants={['p3', 'marginLeft:0.5']}/> : null}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  listWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    position:'relative',
  },
  compactWrapper: {
    maxHeight: 48,
  },
  addBtnWrapper: {
    ...theme.spacing.padding(0),
    width: theme.values.itemHeight.tiny,
    height: theme.values.itemHeight.tiny,
    /* @ts-ignore */
    borderRadius: theme.borderRadius.nanoish
  },
  
  flagWrapper: {
    background: theme.colors.neutral1,
    /* @ts-ignore */
    borderRadius: theme.borderRadius.nanoish,
    ...theme.spacing.padding(0.25),
  },
}), true)
