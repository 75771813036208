/* eslint-disable max-len */
import { React } from '@/app'
import { ExpandableView, usePublicationForm, View } from '@/components'
import {
  ArticleForm,
  AttachmentsForm,
  AuthorForm,
  CoverLetterForm,
  DetailsForm,
  DisclaimersForm,
  SuggestReviewerForm,
} from './modules'
import { PlagiarismChecker } from '../Plagiarism'

export const PublicationForm = () => {
  const publication = usePublicationForm((v) => v.publication)
  const notDraft = publication.status !== 'saved_in_drafts'

  const sections = [
    { id: 'cover-letter-anchor', title: 'Cover Letter', component: <CoverLetterForm />, show: true },
    { id: 'plagium-anchor', title: 'Plagiarism check', component: <PlagiarismChecker id={publication?.id} />, show: notDraft },
    { id: 'article-anchor', title: 'Article', component: <ArticleForm />, show: true },
    { id: 'attachments-anchor', title: 'Attachments', component: <AttachmentsForm />, show: true },
    { id: 'authors-anchor', title: 'Authors', component: <AuthorForm />, show: true },
    { id: 'reviewers-anchor', title: 'Suggest Reviewers', component: <SuggestReviewerForm />, show: true, description: `Max. of ${publication?.reviewers_quantity} reviewers` },
    { id: 'details-anchor', title: 'Details', component: <DetailsForm />, show: true },
  ]

  return (
    <>
      {sections.map(({ id, title, component, show, description }) => show ? (
        <ExpandableView key={id} initialState title={title} id={id} description={description}>
          {component}
        </ExpandableView>
      ) : null,
      )}

      <View id='disclaimers-anchor'>
        <DisclaimersForm />
      </View>
    </>
  )
}
