import { modalFlow } from '@/utils'
import { InviteModal } from './Invite'
import { PreviewInviteModal } from './PreviewInvite'
import { FlagSelector } from '../Flags'
import { FlagDetail } from '../Flags/FlagDetail'

export const InviteFlow = modalFlow([
  InviteModal,
  PreviewInviteModal,
])

export const FlagFlow = modalFlow([
  FlagSelector,
  FlagDetail,
])

// export const AuthFlow = modalFlow([
//   SignupModal,
//   LoginModal,
//   ForgotPasswordModal,
// ])
