import { Journal } from './journal'
import { Publication } from './publication'
import { Publisher } from './publisher'

export enum ProfileRoles {
  author = 'author',
  editor = 'editor',
  reviewer = 'reviewer',
  publisher = 'publisher',
}

export enum RolePermissionsObj {
  author = 'Author',
  reviewer = 'Reviewer',
  publisher = 'Publisher',
  managing_editor = 'Managing editor',
  editor_chiefe = 'Editor-in-chief',
  editor = 'Editor',
  owner = 'Owner'
}

export enum PermissionLevels {
  role_publisher = 'Publisher',
  role_managing_editor = 'Managing Editor',
  role_editor_chiefe = 'Editors-in-Chief',
  role_editor = 'Editor',
  owner = 'Owner',
  superuser = 'Super User',
}

export type RolePermissions = keyof typeof RolePermissionsObj
export type ProfileRole = keyof typeof ProfileRoles

export type PermissionLevel = keyof typeof PermissionLevels

export type ProfileRoleSelector = Record<ProfileRole, boolean>

export type UnavailableDate = {
  title: string
  start_date: string
  end_date: string
}

export type Profile = {
  id: string
  email: string
  first_name: string
  last_name: string
  full_name: string
  date_joined: string
  avatar?: string
  scholar_author_id?: string
  link?: string
  affiliations?: string
  keywords?: string[]
  articles?: ProfileArticles[]
  citations?: Citation[]
  interests?: Interest[]
  website?: string
  tags: number[]
  organisation?: string
  role_permission?: RolePermissions
  publisher_data?: Publisher
  email_notifications: boolean
  is_superuser: boolean
  current_role: ProfileRole
  current_publisher?: number
  current_journal?: string
  is_staff?: boolean
  title: number
  gender: number
  phone?: string
  mobile: string
  degree: string
  address: string
  postcode: string
  position: string
  department: string
  orcid?: string

  region: string
  unavailable_dates?: UnavailableDate[]
}

export type Permission = Record<PermissionLevel, boolean> &
{
  id: number
  profile: Profile
  owner?: boolean
  publisher?: Publisher
  created_datetime: Date
  journal: Pick<Journal, 'title' | 'id'>
}

export type Member = {
  permissions?: Permission[]
  id: number
  target: Profile['id']
  publisher: Publisher['id']
  journal: Journal
  article: Publication['id']
  profile: Profile
}

export type Interest = {
  link: string
  title: string
  serpapi_link: string
}

export type Citation = Record<'year' | 'citations', number>
export type ProfileArticles = {
  link: string
  year: string
  title: string
  authors: string
  cited_by: {
    link: string
    value: number
    cites_id: string
    serpapi_link: string
  }
  citation_id: string
  publication: string
}

export type Gender = {
  id: number
  value: number
  title: string
  label: string
}

export type Title = {
  id: number
  value: number
  title: string
  label: string
}
