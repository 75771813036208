import { variantProvider, React, AppIcon } from '@/app'
import {
  View,
  Text,
  SegmentedControl,
  Table,
  Avatar,
  Button,
  Placeholder,
  BlindfoldedUpload,
  UserDetails,
} from '@/components'
import { APIClient } from '@/services'
import { InvitationReviewer, ReviewerInvitationStatusObj, PossibleReviewer, Publication } from '@/types'
import { AnyFunction, assignTextStyle, capitalize, onUpdate } from '@codeleap/common'
import { ActionIcon, Tag } from '@codeleap/web'
import { useMemo, useState } from 'react'
import { InviteFlow } from '../Modals/flows'

type ReviewersSwitchListsProps = {
  publication: Publication
  showInviteTab?: boolean
  automaticInvitation?: boolean
  refreshPublication: AnyFunction
}

const _Tag = (item: PossibleReviewer | InvitationReviewer) => {
  let tagName: string, tagIcon: AppIcon

  if (item?.previous_reviewer) tagName = 'Previous reviewer', tagIcon = 'undo-2'
  else if (item?.suggestion) tagName = 'Author suggestion', tagIcon = 'pen-tool'
  else if (item?.is_internal) tagName = 'eworkflow reviewer', tagIcon = 'eworkflow-outline'

  return tagName && tagIcon ? (
    <Tag
      text={tagName}
      leftIcon={tagIcon}
      variants={['bg:neutral2', 'padding:1', 'border-radius:nano']}
      responsiveVariants={{
        mid: ['alignSelfStart'],
      }}
      styles={{ text: styles.tagText }}
    />
  ) : <></>
}

export const InviteNameColumnItem = ({ item: _item, isSearch = false }) => {
  const possibleReviewerItem = _item as PossibleReviewer
  const inviteReviewerItem = _item as InvitationReviewer
  const item = isSearch ? possibleReviewerItem : inviteReviewerItem
  //@ts-ignore
  let organisation
  if (isSearch) organisation = item?.organisation || item?.affiliations
  else organisation = item?.possible_reviewer?.organisation || item?.possible_reviewer?.affiliations || item?.profile?.organisation
  const fullName = item?.full_name || 'Name Unavailable'

  return (
    <View
      variants={['gap:2', 'alignCenter', 'fullWidth']}
      responsiveVariants={{
        small: ['gap:1'],
      }}
    >
      <Avatar
        debugName={`Avatar: ${item?.id}`}
        image={item?.profile?.avatar || null}
        name={fullName}
        variants={['mid']}
        responsiveVariants={{
          small: ['small'],
        }}
      />
      <View
        variants={['fullWidth', 'alignCenter', 'gap:1']}
        responsiveVariants={{
          mid: ['column', 'gap:0.5'],
        }}
      >
        <View variants={['center', 'column', 'gap:0.5', 'alignStart', 'fullWidth']}>
          <Text
            text={fullName}
            variants={['p1']}
            responsiveVariants={{
              small: ['p3'],
            }}
            style={styles.nameColumnText}
          />
          <Text
            text={organisation}
            variants={['p3', 'color:neutral6']}
            responsiveVariants={{
              small: ['p3', 'color:neutral6'],
            }}
            style={styles.organisationText}
          />
        </View>
        <_Tag {...item}/>
      </View>
    </View>
  )
}

const renderCitationsColumnItem = (item) => {
  const citations = item?.citations
  const citationsQtd = citations ? citations.reduce((total, citation) => total + (citation?.citations || 0), 0) : null

  return (
    <View variants={['center', 'fullWidth']}>
      <Text text={citationsQtd || 'Not available'} variants={['textCenter']} />
    </View>
  )
}

export const ReviewersSwitchLists = (props: ReviewersSwitchListsProps) => {
  const { publication, showInviteTab = false, automaticInvitation = false, refreshPublication } = props

  const { tableProps: possibleReviewersTableProps } = APIClient.Publications.usePossibleReviewers({ article: publication?.id, limit: 15, invited: false })
  const { tableProps: invitesTableProps, reviewInvites } = APIClient.Publications.useReviewInvites({ article: publication?.id, limit: 15 }, automaticInvitation)
  const reviewerInvitationTemplate = APIClient.Email.useEmails({ code: 'reviewer_invitation' })

  const tabs = useMemo(() => ([
    { value: 'search', label: 'Search', icon: 'search' },
    { value: 'invites', label: `Invites (${invitesTableProps?.numberOfItems || 0})`, icon: 'send' },
  ]), [invitesTableProps?.numberOfItems])

  const [currentTab, setCurrentTab] = useState(tabs[0].value)
  const [selectedItems, setSelectedItems] = useState([])

  const isSearch = currentTab === 'search'

  onUpdate(() => {
    if (showInviteTab && tabs) {
      setCurrentTab(tabs[1].value)
    }
  }, [showInviteTab, tabs])

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab)
  }

  const reviewerDetails = (item, extraArgs = {}) => {
    UserDetails.request({ data: { ...item, ...extraArgs, id: item?.possible_reviewer?.id || item?.id, publication: publication?.id, type: 'reviewer' }})
  }

  const inviteReviewer = async () => {
    const inviteData = await InviteFlow.request({
      publication: publication?.id,
      users: selectedItems,
      subject: reviewerInvitationTemplate?.[0]?.subject || '',
      message: publication?.journal?.reviewer_custom_email,
      type: 'publication',
    })

    await APIClient.Publications.inviteReviewers(inviteData?.publication, inviteData?.users, {
      message: inviteData?.message,
      subject: inviteData?.subject,
    })
  }

  const handleInvite = async () => {
    if (publication.journal.requires_blindfolded_review && publication.requires_blindfolded_upload) {
      const res = await BlindfoldedUpload.request({ publicationId: publication?.id })
      if (res) {
        refreshPublication()
        await inviteReviewer()
      }
    } else {
      await inviteReviewer()
    }

    setSelectedItems([])
    refreshPublication()
    invitesTableProps.query.refetch()
    possibleReviewersTableProps.query.refetch()
  }

  const placeholderText = isSearch ? (
    [
      'The platform is hard at work to find new reviewers for you.',
      `Feel free to give it another shot in a few minutes, or if you'd like, you can manually invite a reviewer by clicking on the new invitation button above.`,
    ]
  ) : (
    ["There's no invites here for now. Manually invite a reviewer by clicking on the new invitation button above."]
  )

  return (
    <>
      <View
        variants={['fullWidth', 'column', 'gap:3']}
        style={{
          'td.main': {
            maxWidth: 'initial !important',
          },
        }}
      >
        <SegmentedControl
          options={tabs}
          value={currentTab}
          onValueChange={handleTabChange}
          variants={['tab', 'fullWidth']}
          styles={{
            'text:selected': styles.segmentedText,
          }}
        />
        {isSearch && selectedItems?.length ? (
          <View variants={['padding:2', 'backgroundColor:neutral2', 'border-radius:small', 'alignCenter', 'row', 'gap:3']}>
            <View variants={['row', 'gap:1']}>
              <ActionIcon icon='x-circle' variants={['minimal', 'normalize']} onPress={() => setSelectedItems([])} />
              <Text variants={['p1', 'bold', 'color:neutral10']} text={`${selectedItems?.length} selected`} />
            </View>
            <Button
              debugName='Send invite'
              text={'Send invitation'}
              icon={'send'}
              variants={['small', 'marginRight:auto']}
              onPress={handleInvite}
            />
          </View>
        ) : null}
        {isSearch && (
          <Table
            {...possibleReviewersTableProps}
            debugName='Reviewrs List'

            onRowPress={reviewerDetails}
            columnMap={[
              {
                label: 'NAME',
                mainColumn: true,
                showBulkSelection: true,
                bulkValues: selectedItems,
                setBulkValues: setSelectedItems,
                component: (item) => <InviteNameColumnItem item={item} isSearch={isSearch} />,
              },
              {
                label: 'CITATIONS',
                component: (item) => renderCitationsColumnItem(item),
              },
              {
                label: 'EMAIL',
                text: ({ email }) => email,
                styles: {
                  itemText: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                },
              },
            ]}
            PlaceholderComponent={() => (
              <Placeholder
                icon={'search'}
                texts={placeholderText}
              />
            )}
          />
        )}
        {!isSearch && (
          <Table
            {...invitesTableProps}
            isLoading={!invitesTableProps?.items?.length}
            debugName='Reviewrs List'
            onRowPress={(item) => reviewerDetails(item, { onDeleteInvite: reviewInvites?.query?.refetch, allowDeleteInvite: item?.status === 'sent' })}
            columnMap={[
              {
                label: 'NAME',
                mainColumn: true,
                showBulkSelection: isSearch,
                bulkValues: selectedItems,
                setBulkValues: setSelectedItems,
                component: (item) => <InviteNameColumnItem item={item} isSearch={isSearch} />,
              },
              {
                label: 'CITATIONS',
                component: (item) => renderCitationsColumnItem(isSearch ? item : item?.possible_reviewer),
              },
              {
                label: 'EMAIL',
                text: ({ email }) => email,
                styles: {
                  itemText: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                },
              },
              {
                label: 'STATUS',
                text: ({ status }) => capitalize(ReviewerInvitationStatusObj[status]),
              },
            ]}
            PlaceholderComponent={() => (
              <Placeholder
                icon={'user-plus'}
                texts={placeholderText}
              />
            )}
          />
        )}

      </View>

    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  segmentedText: {
    fontWeight: '600',
    lineHeight: '20px',
  },
  nameColumnText: {
    overflow: 'hidden',
    textOverflow: 'initial',
    whiteSpace: 'initial',
    letterSpacing: 0,
  },
  organisationText: {
    ...theme.presets.fullWidth,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    letterSpacing: 0,
  },
  tagText: {
    ...assignTextStyle('p3')(theme).text,
    whiteSpace: 'nowrap',

    [theme.media.down('mid')]: {
      ...assignTextStyle('p4')(theme).text,
    },
  },
}), true)
