/* eslint-disable max-len */
import { Theme, variantProvider, getOsAlert, React } from '@/app'
import { Text, View, Avatar, Button, Tag } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { QueryKeys } from '@/services/api'
import { onUpdate, useBooleanToggle, useCallback, useMemo } from '@codeleap/common'
import { useAnimatedStyle, ActivityIndicator } from '@codeleap/web'
import { reviewInvitesManager } from '@/services/api/publication/reviewers/possibleReviewer'
import { modal } from '@/utils'

type UserDetailProps = {data: Partial<{
  full_name: string
  id: number
  publication: number
  onClose: () => void
  allowDeleteInvite?: boolean
  onDeleteInvite: () => void
  type: 'reviewer' | 'author'
}>}

const CITATIONS_TO_SHOW = 5

const CitationsWrapper = ({ citations, visible }) => {
  const citationsToShow = citations
  const [showFullCitations, toggleFullCitations] = useBooleanToggle(false)
  const height = (24 + Theme.spacing.value(1)) * CITATIONS_TO_SHOW

  const animated = useAnimatedStyle(() => {
    return {
      height: showFullCitations ? 'auto' : height,
      transition: {
        duration: 0.5,
      },
    }
  }, [showFullCitations])

  onUpdate(() => {
    if (!visible) toggleFullCitations(false)
  }, [visible])

  return (
    <View variants={['column', 'gap:1']}>
      <Text variants={['p2', 'color:neutral7']} text='Citations' />
      <View
        variants={['column', 'gap:1']}
        animated
        animatedProps={animated}
        style={{
          overflow: 'hidden',
        }}
      >
        {citationsToShow.map((citation) => <Text variants={['p1', 'color:neutral8']} text={`${citation.year}: ${citation.citations}`} />)}
      </View>
      <Button
        text={showFullCitations ? 'See less citations' : 'See more citations'}
        variants={['minimal', 'small']}
        debugName='citations:see more'
        onPress={() => toggleFullCitations()}
        debounce={500}
      />
    </View>
  )
}

const _Tag = (props) => {
  return <Tag
    debugName={`${props?.id ?? props?.text}-tag`}
    variants={['bg:neutral2', 'border-radius:nanoish']}
    {...props}
  />
}

export const UserDetails = modal<UserDetailProps>().content((props) => {
  const { visible, toggle, data } = props

  const { data: reviewerDetails, query: reviewerQuery } = reviewInvitesManager.useRetrieve({
    id: { id: data?.id, article: data?.publication },
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: true,
    },
  })

  const { data: authorDetails, query: authorQuery } = APIClient.Proposals.possibleAuthorsManager.useRetrieve({
    id: { id: data?.id, proposal: data?.publication },
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: data?.type === 'author',
    },
  })

  const userDetails = data?.type === 'author' ? authorDetails : reviewerDetails
  const query = data?.type === 'author' ? authorQuery : reviewerQuery

  const metrics = useMemo(() => {
    return {
      full_name: data?.full_name || userDetails?.full_name,
      email: userDetails?.email,
      status: userDetails?.status,
      invites_received: userDetails?.invites_received || '0',
      invites_accepted: userDetails?.invites_accepted || '0',
      completed_reviews: userDetails?.completed_reviews || '0',
      review_average_time: userDetails?.review_average_time || '0',
      articles: userDetails?.articles,
      interests: userDetails?.interests,
      organisation: userDetails?.organisation,
      affiliations: userDetails?.affiliations,
      website: userDetails?.website,
      tags: userDetails?.tags,
      citations: userDetails?.citations,
      previousReviewer: userDetails?.previous_reviewer,
      internal: userDetails?.is_internal,
      suggestion: userDetails?.suggestion,
    }
  }, [userDetails])

  const articles = useMemo(() => {
    if (!metrics?.articles?.length) return ''
    return metrics.articles.slice(0, 3)
  }, [metrics?.articles, visible])

  const interests = useMemo(() => {
    if (!metrics?.interests?.length) return ''
    return metrics.interests.slice(0, 5).join(', ')
  }, [metrics?.interests])

  const statusTags = useMemo(() => {
    return [
      metrics.internal && {
        text: 'eworkflow reviewer',
        leftIcon: 'eworkflow-outline',
      },
      metrics.previousReviewer && {
        text: 'Previous reviewer',
        leftIcon: 'undo-2',
      },
      metrics.suggestion && {
        text: 'Author suggestion',
        leftIcon: 'pen-tool',
      },
    ].filter(Boolean)
  }, [metrics.internal, metrics.suggestion, metrics.previousReviewer])

  const scientificField = useCallback((id) => QueryKeys?.scientificTag?.getData?.()?.find(t => t.id === id)?.label, [QueryKeys?.scientificTag?.getData?.()])

  const handleDeleteInvite = async () => {
    await AppStatus.set('loading')
    await APIClient.Articles.deleteReviewer({ article: data?.publication, reviewer: data?.id })
    toggle()
    AppStatus.set('done')
    data?.onDeleteInvite?.()
  }

  return (
    <View style={query?.isLoading ? styles.body : {}}>
      {!query?.isLoading ? (
        <>
          <View variants={['gap:3', 'column']}>
            <View variants={['gap:1', 'alignCenter']}>
              <Avatar name={metrics?.full_name || 'Name Unavailable'} debugName={`${metrics?.full_name} Avatar`} variants={['mid']} />

              <View variants={['column']}>
                <Text variants={['h4', 'color:neutral8']} text={metrics?.full_name} />
                <Text variants={['p1', 'color:neutral8']} text={metrics?.organisation || metrics?.affiliations} />
              </View>
            </View>

            {data?.type === 'author' ? null : (

              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='eWorkflow metrics' />
                <View variants={['backgroundColor:neutral2', 'border-radius:tiny', 'padding:2', 'fullWidth']} style={styles.metricsWrapper}>
                  <View variants={['column', 'gap:1']}>
                    <Text variants={['p2', 'color:neutral8']} text={'Invites received'}/>
                    <Text variants={['h5']} text={metrics?.invites_received}/>
                  </View>
                  <View variants={['column', 'gap:1']}>
                    <Text variants={['p2', 'color:neutral8']} text={'Invites accepted'}/>
                    <Text variants={['h5']} text={metrics?.invites_accepted + '%'}/>
                  </View>
                  <View variants={['column', 'gap:1']}>
                    <Text variants={['p2', 'color:neutral8']} text={'Completed reviews'}/>
                    <Text variants={['h5']} text={metrics?.completed_reviews + '%'}/>
                  </View>
                  <View variants={['column', 'gap:1']}>
                    <Text variants={['p2', 'color:neutral8']} text={'Review average time'}/>
                    <Text variants={['h5']} text={Math.round(metrics?.review_average_time / 60 / 60 / 24) + ' days'}/>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.grid}>
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Email address' />
                <Text variants={['p1', 'color:neutral8', 'breakWord']} text={metrics?.email} />
              </View>

              {userDetails?.status ? (
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral7']} text='Invite status' />
                  <Text variants={['p1', 'color:neutral8', 'breakWord', 'capitalize']} text={userDetails?.status} />
                </View>
              ) : null}
              {userDetails?.website ? (
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral7']} text='Website' />
                  <Text variants={['p1', 'color:neutral8', 'breakWord']} text={metrics?.website} />
                </View>
              ) : null}
            </View>

            {interests ? (
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Interests' />
                <Text variants={['p1', 'color:neutral8']} text={interests} />
              </View>
            ) : null}
            {articles ? (
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Manuscripts' />
                <View variants={['column', 'gap:0.5']}>
                  {articles.map(article => (
                    <View variants={['row', 'fullWidth', 'gap:0.5']}>
                      <Text variants={['p1', 'color:neutral8']} text='•' />
                      <Text variants={['p1', 'color:neutral8']} text={article} />
                    </View>
                  ))}
                </View>
              </View>
            ) : null}
            {metrics?.citations?.length ? (
              <CitationsWrapper citations={metrics?.citations} visible={visible} />
            ) : null}
            {statusTags?.length ? (
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Tags' />
                <View variants={['gap:1', 'wrap']}>
                  {statusTags.map(props => <_Tag {...props} />)}
                </View>
              </View>
            ) : null}
            {metrics?.tags?.length ? (
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Scientific fields' />
                <View variants={['gap:1', 'wrap']}>
                  {metrics?.tags?.map(field => <_Tag text={scientificField(field)} />)}
                </View>
              </View>
            ) : null}

            {data?.allowDeleteInvite ? (
              <Button
                variants={['destructive:minimal', 'bg:neutral2', 'marginHorizontal:auto', 'marginTop:2']}
                text='Uninvite'
                icon='send'
                debugName='delete-invite'
                onPress={() => getOsAlert('deleteInvite', { onAccept: handleDeleteInvite })}
              />
            ) : null}

          </View>
        </>
      ) : (
        <View variants={['fullWidth', 'fullHeight', 'center']}>
          <ActivityIndicator variants={['medium', 'alignSelfCenter']} debugName={'Reviewer details ActivityIndicator'} />
        </View>
      )}
    </View>
  )
})

const MODAL_WIDTH = 420

const styles = variantProvider.createComponentStyle((theme) => ({
  box: {
    minWidth: MODAL_WIDTH,
    minHeight: MODAL_WIDTH,
  },
  body: {
    minWidth: MODAL_WIDTH,
    minHeight: MODAL_WIDTH,
    ...theme.presets.justifyCenter,
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    ...theme.spacing.gap(2),
    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.media.down('tiny')]: {
      gridTemplateColumns: '1fr',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    ...theme.spacing.paddingBottom(3),
    ...theme.spacing.gap(3),
    ...theme.border.neutral2({ width: 1, directions: ['bottom'] }),
    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr',
    },
  },
}), true)

UserDetails.props({
  // title: 'Reviewer details',
  styles: {
    box: styles.box,
  },
})
