import { variantProvider, React } from '@/app'
import { Toaster, Text, View, Icon, ManuscriptStatus, useProposalForm } from '@/components'
import { Proposal } from '@/types'
import { DateUtils } from '@/utils'

const ToastLeftContent = ({ proposal }: {proposal: Proposal}) => {
  const createdDate = proposal?.created_datetime ? new Date(proposal?.created_datetime) : null
  const formattedDate = createdDate ? DateUtils.formatDate(createdDate, 'dd/MM/yy') : ''

  return (
    <View variants={['column', 'alignCenter', 'gap:1']}>
      <View
        variants={['gap:0.25', 'alignCenter', 'bg:neutral2', 'paddingVertical:0.25', 'paddingHorizontal:0.5']}
        style={styles.toastLeftContent}
      >
        <Icon debugName='Toaster icon' name='parchment' style={styles.icon} />
        <Text text={proposal?.id} variants={['p3', 'color:neutral9', 'semiBold', 'textCenter']} style={styles.slug} />
      </View>
      <Text text={formattedDate} variants={['p3', 'neutral-8']} />
    </View>
  )
}

const ToastRightContent = ({ proposal }) => {
  return (
    <View variants={['column', 'center', 'gap:1']}>
      <ManuscriptStatus.Tag status={proposal?.status} type='proposal' />
      <Text text={DateUtils.formatDate(proposal?.status_datetime, 'dd/MM/yy')} variants={['p3', 'neutral-8', 'noWrap']} />
    </View>
  )
}

export const ProposalToast = () => {
  const proposal = useProposalForm(v => v.proposal)

  return (
    <Toaster.Component
      title={proposal?.manuscript_title || ''}
      description={proposal?.profile?.name || ''}
      LeftContent={() => <ToastLeftContent proposal={proposal} />}
      RightContent={() => <ToastRightContent proposal={proposal} />}
      variants={['status']}
      isVisible
    />
  )
}

const MAX_WIDTH = 100

const styles = variantProvider.createComponentStyle((theme) => ({
  icon: {
    color: theme.colors.neutral9,
    width: theme.values.iconSize[2],
    height: theme.values.iconSize[2],
    flexShrink: 0,
  },
  toastLeftContent: {
    borderRadius: theme.spacing.value(0.5),
  },
  slug: {
    maxWidth: MAX_WIDTH,
    textWrap: 'wrap',
    wordWrap: 'break-word',
  },
}), true)
