import { TypeGuards, yup } from '@codeleap/common'
import { I18N } from '../i18n'
import { setLocale } from 'yup'
import { humanizedFileSize } from '@/utils'
import { Settings } from '../Settings'
import { FileCategory } from '@/types'

/**Set Form default values */
setLocale({
  mixed: {
    required: () => I18N.t('form.validations.required'),
  },
  string: {
    url: () => I18N.t('form.validations.url'),
    email: () => I18N.t('form.validations.email'),
    min: ({ min }) => I18N.t('form.validations.min', { min }),
    max: ({ max }) => I18N.t('form.validations.max', { max }),
    length: ({ length }) => I18N.t('form.validations.max', { max: length }),
  },
})

function getFileSize(file) {
  return file?.file ? file.file.size : file.size
}

export function validateFileExtension(filename?: string) {
  if (!filename) return false
  const extension = filename?.split?.('.').pop()?.toLowerCase()
  const isExtValid = Settings.FilePickerConfigs.CreatePublication.acceptedExtensions.includes(extension)
  return isExtValid
}

export function fileSizeValidator(file, maxSize = Settings.FilePickerConfigs.Attachments.max) { // 256MB
  let valid = true
  if (TypeGuards.isArray(file)) valid = file.every((f) => getFileSize(f) < maxSize)
  else valid = getFileSize(file) < maxSize

  return {
    valid,
    code: valid ? '' : 'file-too-large',
    message: valid ? '' : 'File is larger than ' + humanizedFileSize(maxSize),
  }
}

export const fileExtByCategoryValidator = (file: File, category: FileCategory) => {
  if (category?.refers_to_main_document || category?.response_to_reviewer_or_editor || category?.clean_version_of_revised_article_with_changes_accepted) {
    const isValidExt = validateFileExtension(file.name)
    return { message: isValidExt ? '' : `File must be a .pdf, .doc or .docx` }
  }
}

export * from './posts'
export * from './auth'
export * from './reviews'
export * from './permissions'
export * from './publication'
export * from './proposal'
export * from './invite'
export * from './journal'
export * from './journalDisclaimer'
export * from './reviewJournalQuestions'
export * from './author'
export * from './reviewer'
export * from './aditionalInfo'
export * from './unavailableDate'
export * from './journalQuestion'
export * from './decision'
export * from './submitTicket'
