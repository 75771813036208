import { React } from '@/app'
import { Text, View, useProposalForm, Button, Proposal } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { PropsOf } from '@codeleap/common'
import { Badge } from '@codeleap/web'

const actionPress = (cb: () => void) => {
  return () => {
    AppStatus.set('loading')
    setTimeout(() => {
      cb()
      AppStatus.set('idle')
    }, 500)
  }
}

type ActionAreaProps = {
  description?: string
  showBadge?: boolean
  buttonProps: PropsOf<typeof Button>
}

const ActionArea = (props: ActionAreaProps) => {
  const { description, showBadge, buttonProps } = props
  return (
    <View variants={['column', 'gap:0.5']}>
      <View variants={['relative']}>
        <Button
          {...buttonProps}
        />
        {showBadge ? <Badge variants={['position:leftTop', 'small']} badge /> : null}
      </View>
      <Text text={description} variants={['p5', 'neutral-7']} />
    </View>
  )
}

export const ProposalBanner = () => {

  const handleGoToAuthors = useProposalForm(v => v.handleGoToAuthors)
  const canEditorEdit = useProposalForm(v => v.canEditorEdit)
  const profile = APIClient.Session.useSession()

  const showActionArea = !canEditorEdit

  return (
    <View variants={['column', 'gap:2', 'fullWidth']}>
      <View variants={['column', 'backgroundColor:neutral2', 'padding:2', 'border-radius:small', 'gap:3']}>
        <Proposal.Toast />
      </View>

      {showActionArea ? (
        <View variants={['gap:2', 'fullWidth', 'wrap']}>
          <ActionArea
            description='Manage authors invites.'
            buttonProps={{
              debugName: 'Open authors invites',
              text: 'Authors and invites',
              icon: 'settings-2',
              variants: ['large', 'manageEditors'],
              onPress: actionPress(handleGoToAuthors),
              disabled: !profile?.profile?.publisher_data?.egenie_enabled,
            }}
          />
        </View>
      ) : null}

    </View>
  )
}

