import { Proposal } from '@/types'
import { api } from '@/app'

const BASE_URL = 'article/proposals/'

type AutomaticInviteParams = { proposalId: Proposal['id']; run: boolean }

export async function automaticInvite({ proposalId, run }: AutomaticInviteParams) {
  const response = await api.patch(BASE_URL + proposalId + '/auto_invite/', { run })

  return response.data
}
