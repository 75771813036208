import { useCallback } from 'react'
import { createContext, useContextSelector as _useContextSelector } from 'use-context-selector'
import { getOsAlert } from '@/app'
import { APIClient } from '@/services'
import { useMemo, useUnmount, TypeGuards } from '@codeleap/common'
import { Placeholder, ProfileRole, Proposal, ProposalStatus, Selector, UseContextType } from '@/types'
import { FormModules, Navigation, disableScroll, formatFileName, useFileState } from '@/utils'
import { AppStatus } from '@/redux'
import { ProposalFormProps, TProposalContext } from './types'
import { SubmitManuscriptAlertModal } from '@/components'
import { ProposalUtils } from '@/utils'

type SaveProposalParams = Partial<Pick<Proposal, 'status'| 'submitted'>> & { onSuccess?: () => void }

const f = () => null

const ProposalFormContext = createContext({} as TProposalContext)

export const ProposalFormProvider = (props: ProposalFormProps) => {
  const {
    children,
    isLoaded,
    proposal,
    proposalQuery,
    article,
    isNew,
  } = props

  const proposals = APIClient.Proposals.proposalsManager.useUpdate()
  const journalLogo = useFileState()

  const modulesRefs: TProposalContext['modulesRefs'] = {
    proposal_details: FormModules.getLazyRef(),
    email_template: FormModules.getLazyRef(),
  }

  const cleanProposal = () => {
    FormModules.cleanModules(modulesRefs)
    journalLogo.delete()
  }

  const { fileName, canEditorEdit, proposalStatus } = useMemo(() => {
    const allowEditorStatus: (keyof typeof ProposalStatus)[] = ['pending', 'indexing']

    return {
      fileName: formatFileName(proposal?.file),
      canEditorEdit: allowEditorStatus.includes(proposal.status),
      proposalStatus: ProposalUtils.getProposalBooleans(proposal?.status),
    }
  }, [proposal?.status, proposal?.file])

  const isSaveProposalEnabled: { [x in ProfileRole]?: boolean } = useMemo(() => {
    // const proposalChanged = modulesRefs.proposal_details.current?.hasChanges

    return { author: false, publisher: true, editor: true }
  }, [proposalStatus])

  useUnmount(() => {
    cleanProposal()
  })

  const handleGoToAuthors = useCallback(async () => {
    Navigation.navigate('eGenie.AuthorsAndInvites', { routeParams: { id: String(proposal?.id) }})
  }, [proposal])

  const refetchProposal = useCallback(async (refetchAllQueries = false) => {
    proposalQuery.refetch().catch(f)
  }, [proposalQuery])

  const saveProposal = useCallback(async (params: SaveProposalParams = {}) => {
    const { status = proposal?.status, submitted = false, onSuccess = null } = params

    disableScroll()
    AppStatus.set('loading')

    const proposalFragments = FormModules.saveModules(modulesRefs)

    const proposalData = Object.assign({ status }, ...proposalFragments)

    try {
      if (canEditorEdit) {
        await Promise.all(FormModules.saveModules(modulesRefs))
      }
      await proposals.update({ id: proposal.id, submitted, ...proposalData })

      AppStatus.set('done')

      if (TypeGuards.isFunction(onSuccess)) await onSuccess()

      await refetchProposal(canEditorEdit)
    } catch (err) {
      AppStatus.set('idle')
      logger.error('Error updating proposal', err, 'Proposals')
      logger.slack.echo('ERROR - manuscript submittion', { err })
      getOsAlert()
    } finally {
      disableScroll(false)
    }
  }, [proposal])

  const updateProposalStatus = useCallback(async (status: Proposal['status']) => {
    await saveProposal({ status })
    return proposal
  }, [saveProposal])

  const handleSubmitProposal = useCallback(async () => {
    const moduleError = FormModules.getModuleError<TProposalContext['modulesRefs']>(modulesRefs)

    if (!!moduleError && !moduleError?.isValid) {
      setTimeout(() => SubmitManuscriptAlertModal.open(moduleError), 300)
      return
    }

    window.scrollTo(0, 0)
    await saveProposal({
      status: 'searching',
      submitted: true,
      onSuccess: () => proposalStatus.isPending
        ? handleGoToAuthors()
        : null,
    })
  }, [modulesRefs, proposalStatus])

  const value: TProposalContext = {
    article,
    fileName,
    isNew,
    ...proposalStatus,
    proposal,
    canEditorEdit,
    isProposalEditable: canEditorEdit,
    isSaveProposalEnabled,
    saveProposal,
    refetchProposal,
    updateProposalStatus,
    proposalLoaded: isLoaded,
    handleGoToAuthors,
    proposalQuery,
    handleSubmitProposal,
    cleanProposal,
    journalLogo,

    modulesRefs,
  }

  return (
    <ProposalFormContext.Provider value={value}>
      {children}
    </ProposalFormContext.Provider>
  )
}

export function useProposalForm<SL extends Selector<TProposalContext, any> = Selector<TProposalContext, Placeholder>>(
  selector?: SL,
): UseContextType<TProposalContext, SL> {
  const value = _useContextSelector(ProposalFormContext, selector ?? ((value) => value))
  return value
}

export * from './types'
