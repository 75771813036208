import { api } from '@/app'
import { AuthorAnswer } from '@/types'
import { ReactQuery } from '@codeleap/common'

const BASE_URL = 'article/proposals/answer/'

export async function answer(params: AuthorAnswer) {
  const response = await api.get(BASE_URL, { params })
  return response.status.toString().startsWith('2')
}

export const useAnswer = (params: AuthorAnswer) => ReactQuery.useQuery({
  queryKey: [`answer-${params.proposal}`],
  queryFn: () => answer(params),
  refetchOnMount: true,
  refetchOnWindowFocus: false,
})
