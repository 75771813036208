import { LocalStorage, LocalStorageKeys } from '@/app'
import { useState } from 'react'
import { Modal } from '../modal'
import { TypeGuards } from '@codeleap/common'
import { TSectionFilterItem } from '@/components'

type Option = TSectionFilterItem

type OptionMap = Array<Option>

type UseSorterProps<T extends OptionMap> = {
  key: keyof typeof LocalStorageKeys
  options: T
  initialValue?: Array<string>
  limit?: number
  hasScroll?: boolean
  modal?: Modal
  sendOptionsToModal?: boolean
  enableLocalStorage?: boolean

  handleExtraArgs?: (args: Record<string, any>) => void
  extraArgs?: Record<string, any>
}

export function useSorter<T extends OptionMap>({ key, options, ...props }: UseSorterProps<T>) {
  const { limit = 1, hasScroll = false, initialValue = [], handleExtraArgs, enableLocalStorage = true, sendOptionsToModal = false, extraArgs } = props

  const [_sortBy, setSortBy] = enableLocalStorage ? LocalStorage.use(key, initialValue) : useState(initialValue)

  const sortBy = (!!_sortBy && TypeGuards.isObject(_sortBy) && Object.keys(_sortBy)) ? _sortBy : []

  const openSorter = async () => {
    const { SortModal } = await import('@/components')

    const modal = props.modal || SortModal.Sort

    const filters = await modal.request({
      options: sendOptionsToModal ? options : [],
      initialState: sortBy,
      limit,
      hasScroll,
      ...extraArgs,
    })

    if (!filters) return

    handleExtraArgs?.(filters)

    const selectedOptions = filters.selectedOptions

    if (selectedOptions.length) {
      const optionValues = selectedOptions?.map((option) => {
        return option.value
      })

      setSortBy(optionValues)
    } else {
      setSortBy([])
    }
  }

  const reset = () => {
    setSortBy(initialValue)
  }

  const currentOption = options?.find?.((o) => o.value === sortBy?.[0])

  return {
    options,
    sortBy,
    setSortBy,
    reset,
    openSorter,
    currentOption,
  }
}
