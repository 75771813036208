import { FileButton } from '@/components'
import { APIClient } from '@/services'
import { Publication } from '@/types'

type SummaryProps = { publication: Publication['id']; version: Publication['version'] }

const DownloadSummary = (props: SummaryProps) => {
  const summary = APIClient.Publications.usePublicationSummary({ id: props?.publication, version: props?.version })

  return <FileButton type={'download'} url={summary?.url}/>
}

const ViewSummary = (props: SummaryProps) => {
  const summary = APIClient.Publications.usePublicationSummary({ id: props?.publication, version: props?.version })

  return <FileButton type={'view'} url={summary?.url}/>
}

export const SummaryButtons = {
  DownloadSummary,
  ViewSummary,
}
