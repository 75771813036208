import { StatusTag } from './StatusTag'
import { statusColorMap } from './colors'

export const ManuscriptStatus = {
  Tag: StatusTag,
}

export const ManuscriptStatusColors = {
  colors: statusColorMap.manuscript,
}
