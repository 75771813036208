import { assignTextStyle, includePresets } from '@codeleap/common'
import { variantProvider } from '..'
import { textStyles } from '../textStyles'

export type ToasterComposition = 'wrapper' | 'innerWrapper' | 'contentWrapper' | 'header' | 'title' | 'description' | 'icon' | 'headerWrapper'

const createToasterStyle =
  variantProvider.createVariantFactory<ToasterComposition>()

const presets = includePresets((styles) => createToasterStyle(() => ({ wrapper: styles })))

export const ToasterStyles = {
  ...presets,
  default: createToasterStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifySpaceBetween,
      ...theme.spacing.padding(2),
      borderRadius: theme.borderRadius.small,
      background: theme.colors.positive1,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(2),
    },
    contentWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.spacing.gap(2),
    },
    headerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.spacing.gap(1),
    },
    header: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
    title: {
      fontSize: theme.typography.base.styles.p2.size,
      fontWeight: theme.typography.base.styles.p2.weight,
      color: theme.colors.neutral10,
    },
    description: {
      fontSize: theme.typography.base.styles.p4.size,
      fontWeight: theme.typography.base.styles.p4.weight,
      color: theme.colors.neutral8,
    },
    icon: {
      color: theme.colors.positive2,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
      flexShrink: 0,
    },
  })),
  publicationsList: createToasterStyle((theme) => ({
    wrapper: {
      width: 'fit-content',
      ...theme.spacing.gap(0.25),
      ...theme.spacing.paddingVertical(0.25),
      ...theme.spacing.paddingHorizontal(0.5),
      borderRadius: theme.borderRadius.nanoish,
      background: theme.colors.neutral2,
    },
    innerWrapper: {
      ...theme.spacing.gap(0.5),
      ...theme.presets.justifySpaceBetween,
    },
    title: {
      fontWeight: '600',
      color: theme.colors.neutral8,
      ...theme.presets.textRight,
    },
    icon: {
      color: theme.colors.neutral8,
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
      flexShrink: 0,
    },
  })),
  status: createToasterStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(2),
      background: theme.colors.neutral1,
    },
    innerWrapper: {
      ...theme.presets.alignStart,
      [theme.media.down('small')]: {
        ...theme.presets.wrap,
      },
    },
    contentWrapper: {
      overflow: 'hidden',
    },
    title: {
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: theme.typography.base.styles.p1.weight,
      overflow: 'hidden',
      wordBreak: 'break-all',
      color: theme.colors.neutral9,
    },
    description: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: theme.typography.base.styles.p3.weight,
      color: theme.colors.neutral8,
    },
  })),
  'status:author': createToasterStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
      background: theme.colors.neutral2,
    },
  })),
  manageEditor: createToasterStyle((theme) => ({
    wrapper: {
      width: 'auto',
      background: theme.colors.neutral2,
      ...theme.spacing.padding(1),
      borderRadius: theme.spacing.value(0.5),
      ...theme.spacing.gap(0.5),
    },
    title: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral8,
    },
  })),
  neutral2: createToasterStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
    },
    icon: {
      color: theme.colors.neutral8,
    },
  })),
  'bg:info1': createToasterStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.info1,
    },
    icon: {
      color: theme.colors.info2,
    },
  })),
  'bg:blue1': createToasterStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.blue1,
    },
    icon: {
      color: theme.colors.blue2,
    },
  })),
  error: createToasterStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive1,
    },
    icon: {
      color: theme.colors.destructive2,

    },
  })),
  alert: createToasterStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.alert1,
    },
    icon: {
      color: theme.colors.alert2,

    },
  })),
  bordered: createToasterStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.small,
      ...theme.border.neutral2({ width: 16 }),
    },
    icon: {
      color: theme.colors.destructive2,
    },
  })),
  blindfolded: createToasterStyle((theme) => ({
    icon: {
      [theme.media.down('largeish')]: {
        display: 'none',
      },
    },
    innerWrapper: {
      [theme.media.down('largeish')]: {
        flexDirection: 'column',
      },
    },
  })),
  progressBanner: createToasterStyle((theme) => ({
    contentWrapper: {
      display: 'none',
    },
    wrapper: {
      backgroundColor: 'transparent'
    },
    title: {
      ...textStyles.p2,
      fontWeight: 600,
      color: theme.colors.neutral10
    },
    description: {
      ...textStyles.p3,
      color: theme.colors.neutral10
    }
  })),
}
