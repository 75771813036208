import { ManuscriptStatus } from '@/components'
import { StatusTagProps } from '@/components/ManuscriptStatus/StatusTag'
import { DateUtils } from '@/utils'
import { View, Text } from '@codeleap/web'

export type StatusColumnProps = {
  status: StatusTagProps['status']
  type: StatusTagProps['type']
  version?: number
  datetime: string
}

export const StatusColumn = ({ version, datetime, ...tagProps }: StatusColumnProps) => (
  <View variants={['column', 'gap:0.5', 'center', 'fullWidth']}>
    {version ? <Text variants={[`p2`, 'color:neutral8', 'regular']} text={`Version ${version}`} /> : null}
    <ManuscriptStatus.Tag {...tagProps} />
    <Text text={DateUtils.formatDate(datetime, 'dd/MM/yy')} variants={['p3', 'color:neutral7']} />
  </View>
)
