/* eslint-disable max-len */
import { React } from '@/app'
import { ExpandableView } from '@/components'
import { ProposalUtils } from '@/utils'
import { ArticleForm, EmailTemplateForm } from './modules'

export const ProposalForm = () => {
  const sections = [
    { id: ProposalUtils.fieldAnchors.proposalFields, title: 'Manuscript', component: <ArticleForm/>, show: true },
    { id: ProposalUtils.fieldAnchors.emailTemplateFields, title: 'Email template customisation', component: <EmailTemplateForm/>, show: true },
  ]

  return sections.map(({ id, title, component, show }) => show ? (
    <ExpandableView key={id} initialState title={title} id={id}>
      {component}
    </ExpandableView>
  ) : null,
  ) as unknown as JSX.Element
}
