import { api } from '@/app'
import { Email, EmailCode } from '@/types'
import { ReactQuery } from '@codeleap/common'
import { QueryKeys } from '.'

const BASE_URL = 'crm/email_templates/'

type FilterParams = {
  code?: EmailCode
}

export function useEmails(filters: FilterParams = {}) {
  const query = ReactQuery.useQuery<Array<Email>>({
    queryKey: ['emails'],
    queryFn: async () => {
      const response = await api.get(BASE_URL, { params: filters })
      return response?.data
    },
    refetchOnWindowFocus: false,
    initialData: null,
    enabled:!!QueryKeys.me.getData(),
    // refetchOnMount: (q) => q.state.dataUpdateCount == 0,
  })

  return query?.data || []
}
