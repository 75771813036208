import { ActivityIndicator, View } from '@/components'
import { JournalSubmissionsDetails } from '@/types'
import { Chart } from 'react-google-charts'

type MapProps = {
  details: JournalSubmissionsDetails
  isDetailsLoading: boolean
  isRefetching: boolean
}

export const Map = (props: MapProps) => {
  const { details, isDetailsLoading, isRefetching } = props

  return (
    <View>
      {isDetailsLoading || isRefetching ? (
        <View variants={['fullWidth', 'alignCenter', 'justifyCenter']}>
          <ActivityIndicator size={40} debugName={'Dashboard Map ActivityIndicator'} />
        </View>
      ) : (
        <Chart
          chartType='GeoChart'
          width='100%'
          height='100%'
          data={[['Country', 'Manuscripts'], ...details.others.articles_by_country]}
          options={{
            colorAxis: {
              colors: ['#E0F7F5', '#00A99D', '#00776B'],
            },
            legend: 'none',
          }}
        />
      )}

    </View>

  )
}
