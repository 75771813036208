/* eslint-disable max-len */
import { React, variantProvider } from '@/app'
import { Button, Text, View, PagePath, useProposalForm, FileButton } from '@/components'

type ProposalHeaderProps = { path: string }

export const EditorActions = () => {
  const proposal = useProposalForm(v => v.proposal)
  const handleSubmitProposal = useProposalForm(v => v.handleSubmitProposal)
  const canEditorEdit = useProposalForm(v => v.canEditorEdit)

  return (
    <>
      <View variants={['gap:2']} responsiveVariants={{ mid: ['fullWidth'] }}>
        <View variants={['row', 'gap:2']}>
          <FileButton type={'download'} url={proposal?.file} />
          <FileButton type={'view'} url={proposal?.file} />
        </View>

        {canEditorEdit ? (
          <Button
            debugName={'Submit proposal'}
            variants={['small', 'center', 'paddingHorizontal:2']}
            styles={{ wrapper: styles?.submitButton, text: styles?.submitButtonTxt }}
            text={'Submit'}
            onPress={handleSubmitProposal}
          />
        ) : null}
      </View>
    </>
  )
}

export const ProposalHeader = (props: ProposalHeaderProps) => {

  const proposal = useProposalForm(v => v.proposal)

  return (
    <>
      <PagePath path={props.path} publication={{ id: proposal?.id, name: 'Proposal details' }} dynamicPath=':id' />
      <View
        variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter', 'gap:2']}
        responsiveVariants={{ largeish: ['column', 'alignStart'] }}
      >
        <View variants={['row', 'gap:1', 'alignCenter']}>

          <Text variants={['h1', 'ellipsis']} text={'Proposal details'} style={styles.pageTitle} />
        </View>
        <EditorActions />
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  pageTitle: {
    ...theme.spacing.marginRight('auto'),
  },
  version: {
    ...theme.spacing.padding(0.75),
    color: theme.colors.neutral8,
    borderRadius: theme.borderRadius.tiny,
    ...theme.border.neutral8({ width: 1 }),
  },
  submitButton: {
    width: 'max-content',
  },
  submitButtonTxt: {
    fontWeight: '600',
  },
}), true)
