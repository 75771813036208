import { variantProvider, React } from '@/app'
import {
  View,
  Text,
  SegmentedControl,
  Table,
  Avatar,
  Button,
  Placeholder,
  UserDetails,
} from '@/components'
import { APIClient } from '@/services'
import { InvitationReviewer, PossibleReviewer, Proposal, AuthorInvitationStatusObj } from '@/types'
import { assignTextStyle, capitalize, onUpdate } from '@codeleap/common'
import { ActionIcon } from '@codeleap/web'
import { useMemo, useState } from 'react'
import { InviteFlow } from '../Modals/flows'

type AuthorsSwitchListsProps = {
  proposal: Proposal
  showInviteTab?: boolean
  automaticInvitation?: boolean
  refreshProposal: () => void
}

export const InviteNameColumnItem = ({ item: _item, isSearch = false }) => {
  const possibleReviewerItem = _item as PossibleReviewer
  const inviteReviewerItem = _item as InvitationReviewer
  const item = isSearch ? possibleReviewerItem : inviteReviewerItem
  //@ts-ignore
  let organisation
  if (isSearch) organisation = item?.organisation || item?.affiliations
  else organisation = item?.possible_reviewer?.organisation || item?.possible_reviewer?.affiliations || item?.profile?.organisation
  const fullName = item?.full_name || 'Name Unavailable'

  return (
    <View
      variants={['gap:2', 'alignCenter', 'fullWidth']}
      responsiveVariants={{
        small: ['gap:1'],
      }}
    >
      <Avatar
        debugName={`Avatar: ${item?.id}`}
        image={item?.profile?.avatar || null}
        name={fullName}
        variants={['mid']}
        responsiveVariants={{
          small: ['small'],
        }}
      />
      <View
        variants={['fullWidth', 'alignCenter', 'gap:1']}
        responsiveVariants={{
          mid: ['column', 'gap:0.5'],
        }}
      >
        <View variants={['center', 'column', 'gap:0.5', 'alignStart', 'fullWidth']}>
          <Text
            text={fullName}
            variants={['p1']}
            responsiveVariants={{
              small: ['p3'],
            }}
            style={styles.nameColumnText}
          />
          <Text
            text={organisation}
            variants={['p3', 'color:neutral6']}
            responsiveVariants={{
              small: ['p3', 'color:neutral6'],
            }}
            style={styles.organisationText}
          />
        </View>
      </View>
    </View>
  )
}

const renderCitationsColumnItem = (item) => {
  const citations = item?.citations
  const citationsQtd = citations ? citations.reduce((total, citation) => total + (citation?.citations || 0), 0) : null

  return (
    <View variants={['center', 'fullWidth']}>
      <Text text={citationsQtd || 'Not available'} variants={['textCenter']} />
    </View>
  )
}

export const AuthorsSwitchLists = (props: AuthorsSwitchListsProps) => {
  const { proposal, showInviteTab = false, automaticInvitation = false, refreshProposal } = props

  const { tableProps: possibleAuthorsTableProps } = APIClient.Proposals.usePossibleAuthors({ proposal: proposal?.id, limit: 15 })
  const { tableProps: invitesTableProps } = APIClient.Proposals.useAuthorInvites({ proposal: proposal?.id, limit: 15 }, automaticInvitation)

  const tabs = useMemo(() => ([
    { value: 'search', label: 'Search', icon: 'search' },
    { value: 'invites', label: `Invites (${invitesTableProps?.numberOfItems || 0})`, icon: 'send' },
  ]), [invitesTableProps?.numberOfItems])

  const [currentTab, setCurrentTab] = useState(tabs[0].value)
  const [selectedItems, setSelectedItems] = useState([])

  const isSearch = currentTab === 'search'

  onUpdate(() => {
    if (showInviteTab && tabs) {
      setCurrentTab(tabs[1].value)
    }
  }, [showInviteTab, tabs])

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab)
  }

  const authorDetails = (item, extraArgs = {}) => {
    UserDetails.request({ data: { ...item, ...extraArgs, id: item?.id, publication: proposal?.id, type: 'author' }})
  }

  const inviteAuthor = async () => {
    const inviteData = await InviteFlow.request({
      publication: proposal?.id,
      users: selectedItems,
      subject: proposal?.email_subject,
      message: proposal?.email_body,
      type: 'proposal',
    })

    await APIClient.Proposals.inviteAuthors({
      id: inviteData.publication,
      subject: inviteData.subject,
      message: inviteData.message,
      users: inviteData.users,
    })

  }

  const handleInvite = async () => {
    await inviteAuthor()

    setSelectedItems([])
    refreshProposal()
    invitesTableProps.query.refetch()
    possibleAuthorsTableProps.query.refetch()
  }

  const placeholderText = isSearch ? (
    [
      'The platform is hard at work to find new authors for you.',
      `Feel free to give it another shot in a few minutes, or if you'd like, you can manually invite authors by clicking on the new invitation button above.`,
    ]
  ) : (
    ["There's no invites here for now. Manually invite an author by clicking on the new invitation button above."]
  )

  return (
    <>
      <View
        variants={['fullWidth', 'column', 'gap:3']}
        style={{
          'td.main': {
            maxWidth: 'initial !important',
          },
        }}
      >
        <SegmentedControl
          options={tabs}
          value={currentTab}
          onValueChange={handleTabChange}
          variants={['tab', 'fullWidth']}
          styles={{
            'text:selected': styles.segmentedText,
          }}
        />
        {isSearch && selectedItems?.length ? (
          <View variants={['padding:2', 'backgroundColor:neutral2', 'border-radius:small', 'alignCenter', 'row', 'gap:3']}>
            <View variants={['row', 'gap:1']}>
              <ActionIcon icon='x-circle' variants={['minimal', 'normalize']} onPress={() => setSelectedItems([])} />
              <Text variants={['p1', 'bold', 'color:neutral10']} text={`${selectedItems?.length} selected`} />
            </View>
            <Button
              debugName='Send invite'
              text={'Send invitation'}
              icon={'send'}
              variants={['small', 'marginRight:auto']}
              onPress={handleInvite}
            />
          </View>
        ) : null}
        {isSearch && (
          <Table
            {...possibleAuthorsTableProps}
            debugName='Authors List'
            onRowPress={authorDetails}
            columnMap={[
              {
                label: 'NAME',
                mainColumn: true,
                showBulkSelection: true,
                bulkValues: selectedItems,
                setBulkValues: setSelectedItems,
                component: (item) => <InviteNameColumnItem item={item} isSearch={isSearch} />,
              },
              {
                label: 'CITATIONS',
                component: (item) => renderCitationsColumnItem(item),
              },
              {
                label: 'EMAIL',
                text: ({ email }) => email,
                styles: {
                  itemText: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                },
              },
            ]}
            PlaceholderComponent={() => <Placeholder icon={'search'} texts={placeholderText}/>}
          />
        )}
        {!isSearch && (
          <Table
            {...invitesTableProps}
            isLoading={!invitesTableProps?.items?.length}
            debugName='Invite Authors List'
            onRowPress={authorDetails}
            columnMap={[
              {
                label: 'NAME',
                mainColumn: true,
                showBulkSelection: isSearch,
                bulkValues: selectedItems,
                setBulkValues: setSelectedItems,
                component: (item) => <InviteNameColumnItem item={item} isSearch={isSearch} />,
              },
              {
                label: 'CITATIONS',
                component: (item) => renderCitationsColumnItem(isSearch ? item : item?.possible_reviewer),
              },
              {
                label: 'EMAIL',
                text: ({ email }) => email,
                styles: {
                  itemText: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                },
              },
              {
                label: 'STATUS',
                text: ({ status }) => capitalize(AuthorInvitationStatusObj[status]),
              },
            ]}
            PlaceholderComponent={() => (
              <Placeholder
                icon={'user-plus'}
                texts={placeholderText}
              />
            )}
          />
        )}

      </View>

    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  segmentedText: {
    fontWeight: '600',
    lineHeight: '20px',
  },
  nameColumnText: {
    overflow: 'hidden',
    textOverflow: 'initial',
    whiteSpace: 'initial',
    letterSpacing: 0,
  },
  organisationText: {
    ...theme.presets.fullWidth,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    letterSpacing: 0,
  },
  tagText: {
    ...assignTextStyle('p3')(theme).text,
    whiteSpace: 'nowrap',

    [theme.media.down('mid')]: {
      ...assignTextStyle('p4')(theme).text,
    },
  },
}), true)
