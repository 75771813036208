import { variantProvider } from '@/app'
import { View, Text, Toaster } from '@/components'
import { ArticleBadgeList } from '@/types'
import { DateUtils } from '@/utils'
import { Badge } from '@codeleap/web'

type IdColumnProps = {
  id: number | string
  datetime: string
  have_badges?: ArticleBadgeList
}

export const IdColumn = ({ id, datetime, have_badges }: IdColumnProps) => {
  const haveBadges =
    have_badges?.submit_decision?.length > 0 ||
    have_badges?.reviewers_and_invites?.length > 0 ||
    have_badges?.history.length > 0 ||
    have_badges?.manuscripts.length > 0

  return (
    <View variants={['column', 'gap:0.5']}>
      <View variants={['relative']}>
        <Toaster.Component
          styles={{ header: styles.header, title: styles.title }}
          title={`${id}`}
          icon={'license' as any}
          variants={['publicationsList']}
          isVisible={true}
        />
        {haveBadges ? <Badge variants={['position:leftTop', 'border']} badge /> : null}
      </View>
      <Text text={DateUtils.formatDate(datetime, 'dd/MM/yy')} variants={['p3', 'color:neutral7']} />
    </View>
  )
}

const MAX_WIDTH = 130

const styles = variantProvider.createComponentStyle(() => ({
  header: {
    maxWidth: MAX_WIDTH,
  },
  title: {
    wordWrap: 'break-word',
    textAlign: 'center',
    textWrap: 'wrap',
  },
}), true)
