import { IsDevEnv, LocalStorage, React, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { useIsomorphicEffect, useState } from '@codeleap/common'
import { ActivityIndicator, View } from '@/components'
import { NavigateOption, Navigation, RoutePath, useReadyTimer } from '@/utils'
import { useLocation } from '@reach/router'

export type AuthenticatedScreenProps = {
  children: React.ReactNode
  redirectOnFailure?: boolean
  redirectRoute?: RoutePath
  redirectOptions?: NavigateOption<any>
  showLoading?: boolean
  autoRedirect?: boolean
}

const READY_TIMEOUT = 10000
const FETCH_COUNT_LIMIT = 4
const FETCH_DELAY = 500
const DEBUG = IsDevEnv && false

export const AuthenticatedScreen = (props?: AuthenticatedScreenProps) => {
  const {
    children,
    redirectOnFailure = false,
    redirectOptions,
    autoRedirect = false,
    redirectRoute = 'Manuscripts.List',
  } = props

  const { isLoggedIn, loginResolved, profileQuery, hasFirebaseUser, logout, profile } = APIClient.Session.useSession()

  const [fetchCounter, setFetchCounter] = useState(0)
  const [ready, setReady] = useState(false)

  const location = useLocation()

  const invalidateSession = async () => {
    if (DEBUG) {
      logger.log('invalidateSession', {
        fetchCounter,
        ready,
        isLoggedIn,
        hasFirebaseUser,
        hasTimer: readyTimer.hasTimer,
      })
    }

    logout({}).then(() => {
      setReady(true)
      setFetchCounter(0)
    })
  }

  const readyTimer = useReadyTimer(ready, invalidateSession, READY_TIMEOUT)

  const invalidateSessionVerification = () => {
    if (readyTimer.hasTimer || fetchCounter !== 0) {
      if (DEBUG) {
        logger.log('invalidateSessionVerification', { fetchCounter, ready, hasTimer: readyTimer.hasTimer })
      }

      setFetchCounter(0)
      readyTimer.cancel()
    }
  }

  const onRedirect = () => {
    if (loginResolved && !isLoggedIn && redirectOnFailure) {
      Navigation.navigate(redirectRoute, redirectOptions)
    }
  }

  const rehydrateSession = async () => {
    await profileQuery.refetch()

    onRedirect()
  }

  useIsomorphicEffect(() => {
    rehydrateSession()

    if (!isLoggedIn && autoRedirect) {
      Navigation.navigate(redirectRoute, redirectOptions)
      return
    }

    if (!!isLoggedIn) {
      setReady(true)
    } else if (!isLoggedIn) {
      setReady(false)
      Navigation.navigate('Auth.Login')
    }
  }, [isLoggedIn, hasFirebaseUser])

  const verifySession = async () => {
    if (profile?.id) return

    const _fetch = await profileQuery.refetch()

    if (DEBUG) {
      logger.log('verifySession', { data: _fetch?.data, fetchCounter, ready })
    }

    if (!_fetch?.data?.id) {
      setFetchCounter(counter => counter + 1)
    }
  }

  useIsomorphicEffect(() => {
    if (ready) {
      invalidateSessionVerification()
      return
    }

    if (fetchCounter > FETCH_COUNT_LIMIT) {
      readyTimer.trigger()
    } else {
      setTimeout(() => {
        verifySession()
      }, FETCH_DELAY)
    }
  }, [fetchCounter, ready])

  useIsomorphicEffect(() => {
    invalidateSessionVerification()
  }, [location?.pathname])

  if (!ready) {
    return (
      <View style={styles.wrapper}>
        <ActivityIndicator debugName='AuthenticatedScreen:loading' />
      </View>
    )
  }

  return <>
    {children}
  </>
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    width: '100vw',
    height: '100svh',
    ...theme.presets.absolute,
    ...theme.presets.whole,
    ...theme.presets.center,
    zIndex: 5,
  },
}), true)
