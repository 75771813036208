import { bannerColors } from "./colors";

export const reviewProgressBanner = {
  draft: {
    ...bannerColors.blue,
    title: "Draft created",
    body: "This review is in a draft state and has NOT been submitted yet."
  },
  submitted: {
    ...bannerColors.green,
    title: "Review submitted",
    body: "The review has now been submitted."
  },
  delete: {
    ...bannerColors.red,
    title: "Review deleted",
    body: "This review has been deleted."
  },
  waitingForApproval: {
    ...bannerColors.darkBlue,
    title: "Waiting for editor’s approval",
    body: "This manuscript is currently waiting for the Editor’s approval."
  }
}