exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-announcements-[id]-tsx": () => import("./../../../src/pages/announcements/[id].tsx" /* webpackChunkName: "component---src-pages-announcements-[id]-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-auth-linked-in-tsx": () => import("./../../../src/pages/auth/linkedIn.tsx" /* webpackChunkName: "component---src-pages-auth-linked-in-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-reset-tsx": () => import("./../../../src/pages/auth/reset.tsx" /* webpackChunkName: "component---src-pages-auth-reset-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-egenie-[id]-authors-and-invites-tsx": () => import("./../../../src/pages/egenie/[id]/authors_and_invites.tsx" /* webpackChunkName: "component---src-pages-egenie-[id]-authors-and-invites-tsx" */),
  "component---src-pages-egenie-[id]-index-tsx": () => import("./../../../src/pages/egenie/[id]/index.tsx" /* webpackChunkName: "component---src-pages-egenie-[id]-index-tsx" */),
  "component---src-pages-egenie-index-tsx": () => import("./../../../src/pages/egenie/index.tsx" /* webpackChunkName: "component---src-pages-egenie-index-tsx" */),
  "component---src-pages-help-frequently-asked-questions-tsx": () => import("./../../../src/pages/help/frequently_asked_questions.tsx" /* webpackChunkName: "component---src-pages-help-frequently-asked-questions-tsx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-in-tray-tsx": () => import("./../../../src/pages/in_tray.tsx" /* webpackChunkName: "component---src-pages-in-tray-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invites-tsx": () => import("./../../../src/pages/invites.tsx" /* webpackChunkName: "component---src-pages-invites-tsx" */),
  "component---src-pages-journals-[id]-index-tsx": () => import("./../../../src/pages/journals/[id]/index.tsx" /* webpackChunkName: "component---src-pages-journals-[id]-index-tsx" */),
  "component---src-pages-journals-index-tsx": () => import("./../../../src/pages/journals/index.tsx" /* webpackChunkName: "component---src-pages-journals-index-tsx" */),
  "component---src-pages-journals-view-[id]-form-tsx": () => import("./../../../src/pages/journals/view/[id]/form.tsx" /* webpackChunkName: "component---src-pages-journals-view-[id]-form-tsx" */),
  "component---src-pages-journals-view-[id]-index-tsx": () => import("./../../../src/pages/journals/view/[id]/index.tsx" /* webpackChunkName: "component---src-pages-journals-view-[id]-index-tsx" */),
  "component---src-pages-manuscripts-[id]-index-tsx": () => import("./../../../src/pages/manuscripts/[id]/index.tsx" /* webpackChunkName: "component---src-pages-manuscripts-[id]-index-tsx" */),
  "component---src-pages-manuscripts-[id]-review-tsx": () => import("./../../../src/pages/manuscripts/[id]/review.tsx" /* webpackChunkName: "component---src-pages-manuscripts-[id]-review-tsx" */),
  "component---src-pages-manuscripts-[id]-reviewers-and-invites-tsx": () => import("./../../../src/pages/manuscripts/[id]/reviewers_and_invites.tsx" /* webpackChunkName: "component---src-pages-manuscripts-[id]-reviewers-and-invites-tsx" */),
  "component---src-pages-manuscripts-index-tsx": () => import("./../../../src/pages/manuscripts/index.tsx" /* webpackChunkName: "component---src-pages-manuscripts-index-tsx" */),
  "component---src-pages-permissions-[id]-tsx": () => import("./../../../src/pages/permissions/[id].tsx" /* webpackChunkName: "component---src-pages-permissions-[id]-tsx" */),
  "component---src-pages-permissions-index-tsx": () => import("./../../../src/pages/permissions/index.tsx" /* webpackChunkName: "component---src-pages-permissions-index-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-proposals-answer-tsx": () => import("./../../../src/pages/proposals/answer.tsx" /* webpackChunkName: "component---src-pages-proposals-answer-tsx" */),
  "component---src-pages-sectionfilter-tsx": () => import("./../../../src/pages/sectionfilter.tsx" /* webpackChunkName: "component---src-pages-sectionfilter-tsx" */)
}

