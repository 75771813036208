import { api } from '@/app'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from '../../queryClient'
import { InvitationAuthor, Profile, Proposal } from '@/types'
import { useManagerTable } from '@/utils'

const BASE_URL = 'article/proposals/'

type ListFilterProps = {
  proposal: number
}

type InviteProps = {
  id: number
  users: Pick<Profile, 'email' | 'full_name'>[]
  subject: string
  message: string
}

export async function inviteAuthors({ users, id, subject, message }: InviteProps) {
  const formattedInvite = users.map(u => ({ ...u, subject, message }))
  const response = await api.post(BASE_URL + id + '/invite/', formattedInvite)

  return response
}

export async function listAuthorInvites(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<InvitationAuthor>>(BASE_URL + 'authors/', {
    params: {
      ...params,
      invited: true,
    },
  })

  return response.data
}

export const authorInvitesManager = new QueryManager<InvitationAuthor>({
  itemType: {} as InvitationAuthor,
  name: 'author_invites',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFilterProps) => {
    const response = await listAuthorInvites({ limit, offset, ...filter })
    return response
  },

})

type UseAuthorInvitesProps = Partial<UsePaginationTypes.PaginationParams> & {
  proposal: Proposal['id']
}

export const useAuthorInvites = (props: UseAuthorInvitesProps, infinity = false) => {
  const {
    proposal,
    ...paginationProps
  } = props

  const [tableProps, authorInvites] = useManagerTable(authorInvitesManager, {
    filter: {
      ...paginationProps,
      proposal,
    },
    listOptions: {
      queryOptions: {
        refetchInterval: infinity ? 3000 : false,
        enabled: false,
      },
    },
    limit: paginationProps?.limit,
  })

  return {
    authorInvites,
    tableProps,
  }
}
