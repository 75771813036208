import { ProposalHeader } from './ProposalHeader'
import { ProposalToast } from './ProposalToast'
import { ProposalBanner } from './ProposalBanner'

export const Proposal = {
  Header: ProposalHeader,
  Toast: ProposalToast,
  Banner: ProposalBanner,
}

export * from './Forms/useProposalForm'
export * from './Forms/ProposalForm'
