import { Proposal } from '@/types'
import { ExternalRequestClientConfig, PaginationResponse, QueryManager, RetrieveOptions, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api, getOsAlert } from '@/app'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Navigation } from '@/utils'

const BASE_URL = 'article/proposals/'

type ProposalStats = {
  url: string
  status: 'done' | 'processing'
}

export function handleDeletion(proposal: Proposal) {
  getOsAlert('handleProposalDeletion', {
    onAction: async () => {
      AppStatus.set('splash')
      await remove(proposal)
      await APIClient.Proposals.proposalsManager.refresh()
      Navigation.navigate('eGenie.List')
      setTimeout(() => {
        AppStatus.set('idle')
      }, 500)
    },
  })
}

export async function getProposalStats(id: Proposal['id']) {
  const response = await api.get<ProposalStats>(`${BASE_URL}${id}/stats/`)
  return response.data
}

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Proposal>>(BASE_URL, {
    params,
  })

  return response.data
}

async function retrieve(proposalId: Proposal['id']) {
  if (!proposalId) throw new Error('Proposal ID is required')
  const response = await api.get<Proposal>(`${BASE_URL}${proposalId}/`)
  return response.data
}

type NewProposalParams = { file: File; params?: ExternalRequestClientConfig }
export async function create({ file, params }: NewProposalParams) {
  const response = await api.post<Proposal>(BASE_URL, {
    file,
    data: {},
  }, { multipart: true, ...params })

  return response.data
}

async function update(proposal: Proposal) {
  const { journal_logo, ...rest } = proposal

  const response = await api.patch<Proposal>(`${BASE_URL}${proposal.id}/`, { data: rest, journal_logo }, { multipart: true })
  return response.data
}

async function remove(proposal: Proposal) {
  await api.delete(`${BASE_URL}${proposal.id}/`)

  return proposal
}

export const proposalsManager = new QueryManager<Proposal>({
  name: 'proposals',
  itemType: {} as Proposal,
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
  /*@ts-ignore*/
  createItem: (data: NewProposalParams) => {
    return create(data)
  },

  retrieveItem: async (id: Proposal['id']) => {
    const response = await retrieve(id)
    return response || null
  },

  updateItem: async (data: Proposal) => {
    const response = await update(data)
    return response
  },
})

export const useRetrieve = ({ id, ...rest }: RetrieveOptions<Proposal>) => {
  const data = proposalsManager.useRetrieve({
    id,
    queryOptions: { enabled: !!id, refetchOnWindowFocus: false },
    ...rest,
  })
  return data
}
