/* eslint-disable max-len */
import { React, variantProvider } from '@/app'
import { Button, Text, View, PagePath, usePublicationForm } from '@/components'
import { SummaryButtons } from './SummaryButtons'
import { APIClient } from '@/services'
import { Publication } from '@/types'

type PublicationHeaderProps = { path: string }
type BaseActionProps = { publication: Publication }

export const AuthorActions = () => {
  const savePublication = usePublicationForm(v => v.savePublication)
  const isSavePublicationEnabled = usePublicationForm(v => v.isSavePublicationEnabled)
  const isRevision_requested = usePublicationForm(v => v.isRevision_requested)
  const handleSubmitPublication = usePublicationForm(v => v.handleSubmitPublication)

  const { profile } = APIClient.Session.useSession()
  return (
    <View variants={['gap:2']} responsiveVariants={{ mid: ['fullWidth'] }}>
      {!isRevision_requested ? <Button
        debugName={'Save manuscript'}
        variants={['small', 'center', 'paddingHorizontal:4', 'flat']}
        text={'Save'}
        onPress={savePublication}
        disabled={!isSavePublicationEnabled?.[profile?.current_role]}
      /> : null}
      <Button
        debugName={'Submit manuscript'}
        variants={['small', 'center', 'paddingHorizontal:4', isRevision_requested ? 'flat' : '']}
        text={isRevision_requested ? 'Save' : 'Submit'}
        onPress={handleSubmitPublication}
      />
    </View>
  )
}

export const EditorActions = (props: BaseActionProps) => {
  const { publication } = props

  const savePublication = usePublicationForm(v => v.savePublication)
  const handleSubmitPublication = usePublicationForm(v => v.handleSubmitPublication)
  const isSavePublicationEnabled = usePublicationForm(v => v.isSavePublicationEnabled)
  const canEditorEdit = usePublicationForm(v => v.canEditorEdit)
  const iseGenie = false

  const { profile } = APIClient.Session.useSession()
  return (
    <>
      <View variants={['gap:2']} responsiveVariants={{ mid: ['fullWidth'] }}>
        <SummaryButtons.DownloadSummary publication={publication?.id} version={publication?.version} />
        <SummaryButtons.ViewSummary publication={publication?.id} version={publication?.version} />

        {canEditorEdit ? (
          <Button
            debugName={'Save publication'}
            variants={['small', 'center', 'paddingHorizontal:2']}
            styles={{ wrapper: styles?.saveButton, text: styles?.saveButtonTxt }}
            text={iseGenie ? 'Submit' : 'Save'}
            onPress={() => iseGenie ? handleSubmitPublication() : savePublication()}
            disabled={!isSavePublicationEnabled?.[profile?.current_role]}
          />
        ) : null}
      </View>
    </>
  )
}

export const PublicationHeader = (props: PublicationHeaderProps) => {

  const publication = usePublicationForm(v => v.publication)
  const canAuthorEdit = usePublicationForm(v => v.canAuthorEdit)

  const { isEditor, isPublisher } = APIClient.Session.useSession()

  return (
    <>
      <PagePath path={props.path} publication={{ id: publication?.id, name: 'Manuscript details' }} dynamicPath=':id' />
      <View
        variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter', 'gap:2']}
        responsiveVariants={{ largeish: ['column', 'alignStart'] }}
      >
        <View variants={['row', 'gap:1', 'alignCenter']}>
          {publication?.version > 1 ? <View style={styles.version}>
            <Text variants={['p2']} text={'Version ' + publication.version} />
          </View> : null}

          <Text variants={['h1', 'ellipsis']} text={'Manuscript details'} style={styles.pageTitle} />
        </View>
        {canAuthorEdit && <AuthorActions publication={publication}/>}
        {(isEditor || isPublisher) && <EditorActions publication={publication} />}
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  pageTitle: {
    ...theme.spacing.marginRight('auto'),
  },
  version: {
    ...theme.spacing.padding(0.75),
    color: theme.colors.neutral8,
    borderRadius: theme.borderRadius.tiny,
    ...theme.border.neutral8({ width: 1 }),
  },
  saveButton: {
    width: 'max-content',
  },
  saveButtonTxt: {
    fontWeight: '600',
  },
}), true)
