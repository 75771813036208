import { ProposalStatus, PublicationStatus } from '@/types'
import { TagProps, Tag } from '@codeleap/web'
import { StatusColorKeys, statusColorMap } from './colors'

export type StatusTagProps = Omit<Partial<TagProps>, 'status' | 'type'> & {
  status: keyof typeof PublicationStatus
  type: StatusColorKeys
}

const tagType: { [x in StatusColorKeys]: {} } = {
  manuscript: PublicationStatus,
  proposal: ProposalStatus,
}

export const StatusTag = (props: StatusTagProps) => {
  const { status, type, variants = [] } = props

  const statusColor = statusColorMap[type][status] || ''

  return (
    <Tag {...props} text={tagType[type]?.[status]} id='statusTag' textProps={{ id: 'status-text' }} variants={['statusTag', 'text:capitalize', statusColor, ...variants]} />
  )
}
