import { map } from "nanostores";
import { useStore } from "@nanostores/react";

import { modal, ModalFlowProps } from "@/utils"
import { View, Button, TextInput, Flag, ActivityIndicator, Text, ActionIcon } from '@/components'
import { React, variantProvider } from "@/app"
import { onUpdate } from "@codeleap/common";

import { FlagWithComment, Publication } from "@/types";
import { APIClient } from "@/services";
import { AppStatus } from "@/redux";

type FlagDetailProps = {
  manuscriptId: Publication['id']
  disabled?: boolean
}

const flagComments = map<{ [x in number]: string }>({})

const FlagItem = ({ flag, ...rest }: FlagWithComment & { disabled?: boolean }) => {
  const store = useStore(flagComments)

  const onChangeText = (str: string) => {
    flagComments.setKey<number>(flag.id, str)
  }

  return (
    <View variants={['column', 'gap:1']}>
      <Flag 
        flag={flag} 
        disabled 
        showName 
      />
      <TextInput 
        debugName="Flag comment"
        multiline
        placeholder={rest?.disabled ? "No comment" : "Add comment"}
        variants={['noError', 'border-radius:tiny', 'multiline']}
        styles={{ 'innerWrapper:hasMultipleLines': styles.inputWrapper }}
        value={store?.[flag?.id]}
        onChangeText={(str) => onChangeText(str)}
        disabled={rest?.disabled}
      />
    </View>
)}

export const FlagDetail = modal<FlagDetailProps>({ id: 'flagDetail' }).content((props) => {
  const { previousOrToggle, toggle, nextOrToggle, manuscriptId, request } = props as ModalFlowProps<'flag', FlagDetailProps>
  const { data: flags, isFetching, isLoading, refetch } = APIClient.Flags.usePublicationFlags(manuscriptId)
  const isReady = !(isFetching || isLoading)

  const handleSubmit = async () => {
    AppStatus.set('loading')
    try {
      const formattedData = Object.entries(flagComments.value)
        .map(([id, comment]) => ({flag: Number(id), comment}))
        .filter(Boolean)
      await APIClient.Flags.update({ data: formattedData, article: manuscriptId })
      AppStatus.set('done')
      setTimeout(() => {
        refetch()
        request.resolve(flags)
      }, 250)
    } catch {
      AppStatus.set('idle')
    }
  }

  onUpdate(() => {
    if (isReady) {
      const formattedFlags = flags?.reduce((acc, flag) => {
        acc[flag.flag.id] = flag.comment || ""
        return acc;
      }, {}) 
      
      flagComments.set(formattedFlags)
    }
  }, [isReady])
  
  return (
    <>
    <View variants={['fullWidth', 'justifySpaceBetween', 'alignCenter', 'marginBottom:4']}>
      <Text variants={['h3', "marginRight:auto"]} text={props?.disabled ? 'Flags Details' : "Add Comments"} />
      {props?.disabled ? <ActionIcon icon="x" variants={['iconSize:3', 'minimal', 'normalize']} onPress={toggle} debugName={""}/> : null}
    </View>
    <View variants={['column', 'gap:4']} style={styles.wrapper}>
      {isReady ? (
        <>
        <View variants={['column', 'gap:3', 'padding:2', 'bg:neutral2', "border-radius:smallish"]} style={styles.list}>
          {flags?.map(flag => (
            <FlagItem key={flag.id} disabled={props?.disabled} {...flag} />
          ))}
        </View>
          {!props?.disabled ? (
            <View style={styles.btnWrapper}>
              <Button 
                debugName="Cancel flag selection"
                text={"Cancel"} 
                onPress={() => previousOrToggle({ flags, manuscriptId })}
                variants={['large', 'flat']}
              />
              <Button 
                debugName="Submit flag"
                text="Continue"
                onPress={handleSubmit}
                variants={['large']}
              />
            </View>
          ) : null}
      </>
      ) : (
        <ActivityIndicator variants={['modal']} debugName="Loading Flags" /> 
      )}  
    </View>
    </>
  )
}).props({
  showClose: false,
})

const LIST_WIDTH = 550
const LIST_HEIGHT = 550

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: LIST_WIDTH,
    width: LIST_WIDTH,
    ...theme.presets.column,

    [theme.media.down('midish')]: {
      width: '100%'
    }
  },
  list: {
    // minHeight: 0,
    // maxHeight: LIST_HEIGHT,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...theme.spacing.paddingRight(2)
  },
  icon: {
    width: 20,
    height: 20, 
  },
  inputWrapper: {
    ...theme.spacing.paddingVertical(1),
  },
  btnWrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.row,
    ...theme.presets.center,
    ...theme.spacing.gap(2),
  }
}), true)