import { getOsAlert, React } from '@/app'
import { Button, Link } from '.'
import { ButtonProps } from '@codeleap/web'
import useDownloader from 'react-use-downloader'
import { formatFileName } from '@/utils'

type BtnTypes = 'view' | 'download'
export type FileButtonProps = {
  type: BtnTypes
  url?: string
  buttonProps?: Partial<ButtonProps>
}

const propsByType: {[x in BtnTypes]: Partial<ButtonProps>} = {
  download: {
    icon: 'download',
    text: 'Download PDF',
    debugName: 'FileButton: download',
  },
  view: {
    icon: 'eye',
    text: 'View PDF',
    debugName: 'FileButton: view',
  },
}

export const FileButton = (props: FileButtonProps) => {
  const { buttonProps, type, url } = props

  const downloader = useDownloader()
  const download = () => {
    if (!url) getOsAlert('downloadAvailableError')
    if (type === 'view') return
    else {
      const filename = formatFileName(url)
      downloader.download(url, filename)
    }
  }

  const button = <Button
    variants={['small', 'center', 'flat', 'loadingLeft']}
    styles={{ text: { fontWeight: '600' }}}
    onPress={download}
    loading={downloader.isInProgress}
    loadingShowText={downloader.isInProgress}
    {...propsByType[type]}
    {...buttonProps}
  />

  switch (type) {
    case 'download':
      return button
    case 'view':
      return url ? <Link openNewTab to={url} variants={['noUnderline']}>
        {button}
      </Link> : button
  }

}
