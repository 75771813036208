import { Toaster as _Toaster } from './Toaster'

import { BlindfoldedToaster } from './BlindfoldedToaster'
import { Plagiarism } from './PlagiarismToaster'
import { ProgressBanner } from './ProgressBanner'
import { bannerColors } from './content/colors'
import { progressBannerStatus } from './content/progressBannerStatus'
import { reviewProgressBanner } from './content/reviewProgressBanners'

const FilesOverview = ({ hasErrors }) => <_Toaster
  title={hasErrors ? 'A problem has been encountered in one or more files.' : 'No viruses or malwares found.'}
  description={hasErrors ? 'One of more of your files contains a virus or malware, please reupload.' : 'Your files are clean! No further action required.'}
  isVisible
  icon={hasErrors ? 'shield-close' : 'shield-check'}
  variants={[hasErrors && 'error']}
/>

const Scanning = () => <_Toaster
  title="Verification in progress - please don't close the window, this can take a few moments."
  description="We are searching for any viruses or malwares that may be contained in the files you've submitted."
  isVisible
  icon={'shield'}
  variants={['neutral2']}
/>

const Infected = () => <_Toaster
  title='A problem has been identified in the files you uploaded, please re-upload to continue.'
  description='One of more of your files contains a virus or malware, please reupload.'
  isVisible={true}
  icon={'shield-alert'}
  variants={['alert']}
/>

const ManuscriptSubmited = ({ isVisible }) => <_Toaster
  title='Your manuscript has been submited.'
  description='Follow the status of your article now to stay updated on its current status and next steps.'
  icon={'send' as any}
  isVisible={isVisible}
  variants={['bg:blue1']}
/>

export const Toaster = {
  BlindfoldedToaster,
  FilesOverview,
  Scanning,
  Plagiarism,
  Infected,
  ManuscriptSubmited,
  ProgressBanner,

  Component: _Toaster,

  bannerColors,
  progressBannerStatus,
  reviewProgressBanner
}
