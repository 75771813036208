import { AppForms, Settings, variantProvider } from '@/app'
import { View, TextInput, useProposalForm, TextEditor, TextEditorExtensions, Text, TemplateVariables, PicturePicker } from '@/components'
import { ProposalUtils, useDebouncedEditor, useMemoizedForm } from '@/utils'
import { deepEqual, useCallback, useImperativeHandle, useMemo, useState } from '@codeleap/common'
import Placeholder from '@tiptap/extension-placeholder'

const MIN = 15
const MAX = 10000
const WIDTH = Settings.CropPickerConfigs.default.width
const HEIGHT = Settings.CropPickerConfigs.default.height

export const EmailTemplateForm = () => {
  const isProposalEditable = useProposalForm(v => v.isProposalEditable)
  const proposal = useProposalForm(v => v.proposal)
  const ref = useProposalForm(v => v.modulesRefs.email_template)
  const journalLogo = useProposalForm(v => v.journalLogo)

  const initialState = useMemo(() => ({
    proposal: proposal?.proposal,
    email_subject: proposal?.email_subject,
    email_body: proposal?.email_body,
  }), [proposal?.proposal, proposal?.email_subject, proposal?.email_body])

  const form = useMemoizedForm(AppForms.editProposalEmail, { validateOn: 'change', initialState })
  const [validate, setValidate] = useState(false)

  const editor = useDebouncedEditor({
    content: form?.values.email_body,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Email body here', emptyEditorClass: 'placeholder' })],
    onUpdate: ({ editor }) => form.setFieldValue('email_body', editor.getHTML()),
    onBlur: () => setValidate(true),
    editable: isProposalEditable,
  })

  const onClear = () => form.setFormValues({ proposal: '', email_subject: '', email_body: '' })

  const onSave = () => {
    const proposalFormData = {
      proposal: form.values.proposal?.trim(),
      email_subject: form.values.email_subject?.trim(),
      email_body: form.values.email_body?.trim(),
    }

    return { ...ProposalUtils.verifyProposalChanges(proposal, proposalFormData), journal_logo: journalLogo.file }
  }

  const onValidate = () => {
    const fieldsToValidate = ['proposal', 'email_subject', 'email_body'] as const

    return {
      isValid: form?.validateMultiple?.(fieldsToValidate)?.valid,
      anchor: ProposalUtils.fieldAnchors.emailTemplateFields,
    }
  }

  const error = useMemo(() => {
    let errorMessage: string
    if (editor?.getText().length <= MIN) errorMessage = `Minimum of ${MIN} characters`
    if (editor?.getText().length >= MAX) errorMessage = `Maximum of ${MAX} characters`

    return validate ? errorMessage : ''
  }, [form.values, validate])

  useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    showError: () => form.validateAll(true),
    hasChanges: !deepEqual(form.values, initialState),
  }))

  const handleForm = useCallback(field => {
    const fieldFormError = form.fieldErrors[field]
    const fieldValidation = form.validateField(field)

    return {
      ...form.register(field),
      disabled: !isProposalEditable,
      _error: fieldFormError && !fieldValidation.valid ? fieldValidation.message ?? fieldFormError : '',
    }
  }, [form])

  return (
    <View variants={['gap:2', 'column']}>
      <View style={styles.logoWrapper}>
        <PicturePicker
          label='Journal Logo'
          description={`Recommended image resolution: ${WIDTH} x ${HEIGHT} pixels`}
          size={Settings.CropPickerConfigs.default}
          onPickImage={({ file }) => journalLogo.set(file)}
          onDeleteImage={journalLogo.delete}
          initialImage={proposal?.journal_logo}
          disabled={!isProposalEditable}
        />
      </View>
      <TextInput {...handleForm('proposal')} />
      <TextInput {...handleForm('email_subject')}/>
      <View variants={['column', 'fullWidth', 'gap:1']}>
        {isProposalEditable ? <TemplateVariables type={'proposal'} variants={['marginBottom:3']} /> : null}
        <Text text='Message' variants={['p3', 'color:neutral7', 'semiBold']}/>
        <TextEditor
          toolbarComponentProps={{ editor, excludeIds: ['image', 'fileComponent'], hide: !isProposalEditable }}
          textEditorProps={{
            editor,
            variants: ['reviewerQuestionsForm', 'multiline', !isProposalEditable ? 'textInput:disabled' : ''],
            ...handleForm('email_body'),
            validate: false,
            _error: error,
          }}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  logoWrapper: {
    width: WIDTH,
  },
}), true)
