import {
  Journal,
  Permission,
  PermissionLevel,
  PermissionLevels,
  Profile,
  ProfileRoles,
  RolePermissions,
} from '@/types'
import { TypeGuards, capitalize } from '@codeleap/common'
import { Navigation, RoutePath } from './navigation'
import { ROUTES } from '@/app'

/**
 * Check if the provided role is allowed based on the allowedRoles array.
 *
 * @param {Profile['role'][]} allowedRoles - An array of allowed roles.
 * @param {Profile['role']} roles - An array of roles or an role to be compared.
 * @returns {boolean} Returns true if the role is allowed, false otherwise.
 */
const isRoleAllowed = (
  allowedRoles: Profile['current_role'][],
  roles: Profile['current_role'][] | Profile['current_role'],
) => TypeGuards.isArray(roles)
  ? allowedRoles.some((role) => roles.includes(role))
  : allowedRoles.includes(roles)

type RoleBooleans = {
  [key in `is${Capitalize<keyof typeof ProfileRoles>}`]: boolean;
}

/**
 * Generates a boolean object representing the role based on the provided role value.
 *
 * @param {Profile['role']} role - The role value to be used for generating boolean values.
 * @returns {RoleBooleans} An object with boolean values indicating the presence of each role.
 *
 * E.g: { isAuthor: true, isEditor: false, isPublisher: false, isReviewer: false }
 */
const getRoleBooleans = (role: Profile['current_role']) => {
  const rolesObject: RoleBooleans = {} as RoleBooleans

  Object.keys(ProfileRoles).forEach((key) => {
    rolesObject[`is${capitalize(key)}`] = role === ProfileRoles[key]
  })
  return rolesObject
}

const levelPrivileges: Partial<Record<PermissionLevel, string[]>> = {
  role_editor: [
    'Manuscript Handling: Editors have the privilege to manage and oversee the review process for assigned manuscripts, including assigning reviewers, monitoring progress, and making editorial decisions based on reviewer feedback.',
    'Decision Making: Editors have the authority to make final decisions on manuscript acceptance, rejection, or revision based on reviewer comments, editorial policies, and journal guidelines.'],
  role_publisher: [
    'Publication Management: Publishers have the privilege to oversee the publication process, including scheduling manuscript releases, coordinating with production teams for typesetting and formatting, and ensuring timely publication of accepted manuscripts.',
  ],
  role_editor_chiefe: [
    'Editorial Oversight: The Editor in Chief has the overall responsibility for the editorial content and direction of the journal. They have the privilege to set editorial policies, establish editorial board members, and ensure the integrity and quality of published manuscripts.',
    'Editorial Decision Review: The Editor in Chief may have the privilege to review and override editorial decisions made by other editors, especially in cases of conflicting opinions or significant discrepancies.',
  ],
  role_managing_editor: [
    'Workflow Coordination: Managing Editors have the privilege to coordinate the overall workflow of the manuscript management system, including assigning tasks to editors and reviewers, tracking manuscript progress, and ensuring timely responses to authors.',
    'Technical Administration: Managing Editors may have the privilege to manage the technical aspects of the manuscript management system, such as user accounts, database maintenance, software updates, and troubleshooting technical issues.',
  ],
}

const getRoleName = (permission: Permission) => {
  if (!permission) return 'Member'

  if (!!permission?.role_editor) return PermissionLevels.role_editor
  if (!!permission?.role_editor_chiefe) return PermissionLevels.role_editor_chiefe
  if (!!permission?.role_managing_editor) return PermissionLevels.role_managing_editor
  if (!!permission?.role_publisher) return PermissionLevels.role_publisher
  return ''
}

export type HasPermissionsProps = {
  permissions: Permission[]
  current_role: Profile['current_role']
  level?: PermissionLevel
  levels?: PermissionLevel[]
  journals?: Journal['id'][]
}

const checkPermission = ({ permissions = [], level, journals = [], current_role }: HasPermissionsProps) => {
  if (current_role !== 'publisher') return false

  const isOwner = permissions.some((permission) => !!permission.owner)
  const isSuperUser = permissions.some((permission) => !!permission.superuser)

  if (journals.length > 0 && !isOwner && !isSuperUser) {
    return permissions.some((permission) => !!permission[level] && journals.includes(permission.journal?.id))
  }

  return permissions.some((permission) => !!permission[level])
}

const hasPermission = ({ permissions = [], level, levels = [], journals = [], current_role }: HasPermissionsProps) => {
  if (level) return checkPermission({ permissions, level, journals, current_role })
  return levels.some((level) => checkPermission({ permissions, level, journals, current_role }))
}

//** Add any pathname that you want to block for each role */
export const BlockedRoutes = {
  author: [
    ...Navigation.getStackRoutes('Auth'),
    ...Navigation.getStackRoutes('Invites'),
    ...Navigation.getStackRoutes('Permissions'),
    ...Navigation.getStackRoutes('Dashboard'),
    ...Navigation.getStackRoutes('InTray'),
    ...Navigation.getStackRoutes('eGenie'),
    ROUTES.Journals.View.Form,
    ROUTES.Manuscripts.Review,
    ROUTES.Manuscripts.ReviewersAndInvites,
  ],

  reviewer: [
    ...Navigation.getStackRoutes('Auth'),
    ...Navigation.getStackRoutes('Permissions'),
    ...Navigation.getStackRoutes('Dashboard'),
    ...Navigation.getStackRoutes('InTray'),
    ...Navigation.getStackRoutes('Archive'),
    ...Navigation.getStackRoutes('eGenie'),
    ROUTES.Journals.View.Form,
    ROUTES.Manuscripts.ReviewersAndInvites,
    ROUTES.Manuscripts.Details,
  ],

  editor: [
    ...Navigation.getStackRoutes('Auth'),
    ...Navigation.getStackRoutes('Permissions'),
    ...Navigation.getStackRoutes('Dashboard'),
    ...Navigation.getStackRoutes('Invites'),
    ROUTES.Journals.View.Form,
    ROUTES.Manuscripts.Review,
  ],

  publisher: [
    ...Navigation.getStackRoutes('Auth'),
    ...Navigation.getStackRoutes('Invites'),
    ROUTES.Manuscripts.Review,
  ],

  owner: [
    ...Navigation.getStackRoutes('Auth'),
    ...Navigation.getStackRoutes('Invites'),
    ROUTES.Manuscripts.Review,
  ],

  superuser: [],
}

// if (hasPermission({ level: 'superuser', ...permissionProps })) {
//   return BlockedRoutes.superuser
// }
export const getBlockedRolesByRole = (role: Profile['current_role'], permissions: Permission[]) => {
  if (role === 'author') { return BlockedRoutes.author }
  if (role === 'reviewer') { return BlockedRoutes.reviewer }

  if (role === 'editor') {
    return BlockedRoutes.editor
  }

  if (role === 'publisher') {
    const permissionProps = { permissions, current_role: role }

    if (hasPermission({ level: 'owner', ...permissionProps })) {
      return BlockedRoutes.owner
    }

    if (hasPermission({ level: 'role_publisher', ...permissionProps })) {
      return BlockedRoutes.publisher
    }
  }

  return []
}

export const getRedirectRouteByRole = (role: RolePermissions): RoutePath => {
  switch (role) {
    case 'author':
      return 'NotFound'
    case 'editor':
      return 'NotFound'
    case 'publisher':
      return 'NotFound'
    case 'reviewer':
      return 'NotFound'
    default:
      return 'NotFound'
  }
}

type isPathAllowedProps = {
  role: keyof typeof ProfileRoles
  pathname: string
  permissions: Permission[]
}

function matchRoutes(pathname: string, routes: string[]) {
  const normalizedRoutes = routes.map(route => {
    return route.replace(/{{\w+}}/g, '[^/]+')
  })

  const normalizedPathname = pathname.length > 1 ? pathname.replace(/\/$/, '') : pathname

  return normalizedRoutes.some(route => {
    const regex = new RegExp(`^${route}$`)
    return regex.test(normalizedPathname)
  })
}

function isRouteBlocked({ pathname, role, permissions = [] }: isPathAllowedProps) {
  const blockedRoutes = getBlockedRolesByRole(role, permissions).map(route => {
    // Convert '{{id}}' or similar placeholders into a pattern
    return route.replace(/{{\w+}}/g, '[^/]+')
  },
  )

  // Normalize the pathname to ensure it doesn't end with a slash unless it's the only character
  const normalizedPathname = pathname.length > 1 ? pathname.replace(/\/$/, '') : pathname

  const isBlocked = blockedRoutes.some(routePattern => {
    const regex = new RegExp(`^${routePattern}$`)
    return regex.test(normalizedPathname)
  })

  return isBlocked
}

const isRoutePublic = (pathname: string) => {

  const publicRoutes = [
    ROUTES.Auth.Login,
    ROUTES.Auth.Signup,
    ROUTES.Auth.Reset,
    ROUTES.Journals.Login,
    ROUTES.eGenie.Answer,
    ROUTES.NotFound,
  ]

  return matchRoutes(pathname, publicRoutes)

}

const permissionInitialState: Partial<Permission> = {
  journal: { value: '', label: '' },
  profile: null,
  role_editor: false,
  role_editor_chiefe: false,
  role_managing_editor: false,
  role_publisher: false,
  superuser: false,
}

export const PermissionUtils = {
  getRoleName,
  isRoleAllowed,
  getRoleBooleans,
  hasPermission,
  isRouteBlocked,
  getRedirectRouteByRole,
  isRoutePublic,
  levelPrivileges,
  permissionInitialState,
}
