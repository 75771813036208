import { createForm, yup } from '@codeleap/common'

export const editProposal = () => createForm('editProposal', {
  manuscript_title: {
    label: 'Title',
    type: 'text',
    debugName: 'Proposal form: title',
    placeholder: 'Article title',
    validate: yup.string().nullable().required()
      .trim()
      .max(300),
  },
  journal_name: {
    label: 'Journal Name',
    type: 'text',
    debugName: 'Proposal form: Journal name',
    placeholder: 'Journal name',
    validate: yup.string().nullable().required()
      .trim()
      .max(256),
  },
  keywords: {
    label: 'Semantic Analysis (Keywords/Concepts)',
    multiline: true,
    type: 'text',
    debugName: 'Proposal section: Keywords',
    validate: yup.string().max(300).required()
      .trim(),
  },
})

export const editProposalEmail = () => createForm('editProposalEmail', {
  proposal: {
    label: 'Proposal description',
    description: 'Why or for what the author is being invited',
    type: 'text',
    debugName: 'Proposal form: proposal variable',
    placeholder: 'Description',
    validate: yup.string().nullable().required()
      .trim()
      .max(256),
  },
  email_subject: {
    label: 'Subject',
    type: 'text',
    debugName: 'Proposal form: subject',
    placeholder: 'Email subject here',
    validate: yup.string().nullable().required()
      .trim()
      .max(300),
  },
  email_body: {
    type: 'text',
    multiline: true,
    validate: yup.string().notRequired()
      .min(22, 'Minimum of 15 characters')
      .max(10000, 'Maximum of 10000 characters'),
  },
})
