import { ProposalStatus, PublicationStatus } from '@/types'

type StatusColorMap = {
  'manuscript': {[x in keyof typeof PublicationStatus]?: string}
  'proposal': {[x in keyof typeof ProposalStatus]?: string}
}
export type StatusColorKeys = keyof StatusColorMap

export const statusColorMap: StatusColorMap = {
  manuscript: {
    saved_in_drafts: 'darkBlue',
    submitted_for_review: 'blue',
    under_review: 'blue',
    pending_review: 'orange',
    reviewers_invited_accepted: 'orange',
    review_submitted: 'blue',
    rejected: 'red',
    accepted: 'green',
    revision_requested: 'yellow',
    revision_in_drafts: 'darkBlue',
    revision_completed: 'blue',
    rejected_transfer: 'red',
    rejected_resubmit: 'red',
    withdraw: 'darkBlue',
  },
  proposal: {
    indexing: 'darkBlue',
    pending: 'darkBlue',
    searching: 'blue',
    inviting: 'orange',
    accepted: 'green',
    denied: 'red',
    unanswered: 'darkBlue',
  },
}
