export const bannerColors = {
  green: {
    backgroundColor: "green2",
    iconColor: "green3"
  },
  gray: {
    backgroundColor: "gray1",
    iconColor: "gray2"
  },
  orange: {
    backgroundColor: "orange2",
    iconColor: "orange3"
  },
  red: {
    backgroundColor: "red2",
    iconColor: "red3"
  },
  blue: {
    backgroundColor: 'blue5',
    iconColor: 'blue6',
  },
  yellow: {
    backgroundColor: "yellow2",
    iconColor: "yellow3"
  },
  darkBlue: {
    backgroundColor: "blue7",
    iconColor: "blue8"
  }
} as const