import React, { useMemo, useState } from 'react'
import { InviteNameColumnItem, UserDetails, ReviewerMetrics, Select, Table, Tag, Text, View } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { variantProvider } from '@/app'
import { StylesOf, TypeGuards } from '@codeleap/common'
import { modal, useManagerTable } from '@/utils'
import { InvitationReviewer, InvitationStatus, Publication } from '@/types'
import { ModalComposition } from '@codeleap/web'

function StatusTag({ item }) {
  const possibleStatus: Record<InvitationStatus, any> = {
    accepted: {
      text: 'Accepted',
      css: { backgroundColor: '#DBF3E8' },
      styles: { text: { color: '#298B5C' }},
    },
    declined: {
      text: 'Declined',
      css: { backgroundColor: '#FFE5E9' },
      styles: { text: { color: '#E52727' }},
    },
    sent: {
      text: 'No response',
      css: { backgroundColor: '#F8ECDD' },
      styles: { text: { color: '#DA6716' }},
    },
    review_complete: {
      text: 'Accepted',
      css: { backgroundColor: '#DBF3E8' },
      styles: { text: { color: '#298B5C' }},
    },
  }

  return (
    <Tag
      variants={['statusTag']}
      debugName={`invite status ${item?.status}`}
      {...possibleStatus[item?.status || 'sent']}
    />
  )
}

const LIMIT = 25

type InviteDetailsProps = {
  publicationId: Publication['id']
  version: number
}

export const InvitesDetails = modal<InviteDetailsProps>().content((props) => {
  const { publicationId, version: initialVersion = 1 } = props
  const publication = APIClient.Publications.useRetrieve({
    id: publicationId,
  })

  const data = publication.data

  const [version, setVersion] = useState(initialVersion)

  const [tableProps] = useManagerTable(APIClient.Publications.invitedReviewersManager, {

    filter: {
      limit: LIMIT,
      article: data?.id,
      version: version,
      status_priority_order: 'review_complete,accepted,declined,sent',
    },
    listOptions: {
      queryOptions: {
        enabled: !!(data?.id),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },

    limit: LIMIT,
  })

  function openReviewerDetails(reviewer: InvitationReviewer) {
    UserDetails.open({
      data: {
        ...reviewer,
        id: reviewer?.possible_reviewer?.id || reviewer?.profile?.id,
        publication: reviewer?.article,
        type: 'reviewer',
      },
    })
  }

  const fields = useMemo(() => {
    return [
      { title: 'Currently reviewing', field: data?.title },
      { title: 'Journal', field: data?.journal?.title },
      { title: 'Author', field: data?.author },
      {
        title: 'Manuscript version',
        field: () => {
          const options = Array.from({ length: data?.version || 0 }).map((_, i) => ({
            value: i + 1,
            label: `${i + 1}`,
          }))

          return (
            <Select
              options={options}
              value={version}
              placeholder={data?.version}
              onValueChange={setVersion}
              debugName='select version'
              styles={selectStyles}
            />
          )
        },
      },
      {
        title: 'Review metrics',
        field: () => <ReviewerMetrics version={version} article={data?.id} />,
      },
      {
        title: 'Invite status',
        field: () => {
          return (
            <Table
              {...tableProps}
              debugName='reviewers details table'
              showHeader={false}
              onRowPress={(reviewer) => openReviewerDetails(reviewer)}
              columnMap={[
                { mainColumn: true, component: (item) => <InviteNameColumnItem item={item} /> },
                { component: (item) => <StatusTag item={item} /> },
              ]}
            />
          )
        },
      },
    ]
  }, [data, version, tableProps])

  return (
    <>
      {fields.map(({ title, field }) => (
        <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text={title} />
          {TypeGuards.isFunction(field) ? field() : <Text variants={['p1', 'color:neutral8']} text={field} />}
        </View>
      ))}
    </>

  )
})

const MAX_WIDTH = 800

const modalStyles = variantProvider.createComponentStyle<StylesOf<ModalComposition>>(theme => ({
  box: {
    maxWidth: MAX_WIDTH,
    ...theme.presets.fullWidth,
  },
  body: {
    ...theme.presets.column,
    ...theme.spacing.gap(2),
  },
}), true)

const selectStyles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.spacing.marginRight('50%'),
  },
  placeholder: {
    color: theme.colors.neutral9,
  },
  errorMessage: {
    display: 'none',
  },
}), true)

InvitesDetails.props({
  title: 'Reviewers and invites details',
  styles: modalStyles,
})
